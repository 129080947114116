import React, { useEffect, useState } from 'react'
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { Formik } from 'formik'
import * as yup from 'yup'
import { useUpdateUserMutation } from './usersApiSlice'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'

const checkoutSchema = yup.object().shape({
  username: yup.string().required('required'),
  // password: yup.string().required('required'),
  roles: yup
    .array()
    .min(1, 'At least one role must be selected')
    .required('Roles are required'),
  //   email: yup.string().email('invalid email').required('required'),
})

const EditUserForm = ({ user }) => {
  const [updateUser, { isSuccess }] = useUpdateUserMutation()

  const [isAlertVisible, setAlertVisible] = useState(false)

  const initialValues = {
    username: user.username,
    // password: '',
    roles: user.roles,
    //   email: '',
  }

  const handleButtonClick = () => {
    setAlertVisible(true)
  }

  const handleCloseAlert = () => {
    setAlertVisible(false)
  }

  const handleFormSubmit = async (values, onSubmitProps) => {
    await updateUser({ id: user.id, ...values })
    handleButtonClick()
    // onSubmitProps.resetForm()
  }

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        handleCloseAlert()
      }, 3000)
    }
  }, [isSuccess])

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValues}
      validationSchema={checkoutSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              '& > div': { gridColumn: undefined },
            }}
          >
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="User Name"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.username}
              name="username"
              error={!!touched.username && !!errors.username}
              helperText={touched.username && errors.username}
              sx={{ gridColumn: 'span 2' }}
            />
            {/* <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Password"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.password}
              name="password"
              error={!!touched.password && !!errors.password}
              helperText={touched.password && errors.password}
              sx={{ gridColumn: 'span 2' }}
            /> */}

            <FormControl
              fullWidth
              variant="filled"
              sx={{ gridColumn: 'span 2' }}
            >
              <InputLabel>Select Roles</InputLabel>
              <Select
                label="Select Roles"
                multiple
                value={values.roles}
                name="roles"
                onChange={handleChange}
                error={!!touched.roles && !!errors.roles}
                sx={{ gridColumn: 'span 2' }}
              >
                <MenuItem value="Student">Student</MenuItem>
                <MenuItem value="Staff">Staff</MenuItem>
                <MenuItem value="Admin">Admin</MenuItem>
              </Select>
              {touched.roles && errors.roles && (
                <Box color="red" fontSize="0.75rem">
                  {errors.roles}
                </Box>
              )}
            </FormControl>
          </Box>
          <Box display="flex" justifyContent="end" mt="20px">
            <Button type="submit" color="secondary" variant="contained">
              Update User
            </Button>
          </Box>
          {isAlertVisible && (
            <Alert severity="success" onClose={handleCloseAlert}>
              <AlertTitle>成功</AlertTitle>
              ユーザ情報の変更に成功しました
            </Alert>
          )}
        </form>
      )}
    </Formik>
  )
}

export default EditUserForm
