import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import { Provider } from 'react-redux'
import store from 'state/store'
import checkIsProductionMode from 'utils/checkIsProductionMode'
import { disableReactDevTools } from '@fvilers/disable-react-devtools'

if (checkIsProductionMode()) {
  console.log('Production mode')
  disableReactDevTools()
} else {
  console.log('Development mode')
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
)
