import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { prism } from 'react-syntax-highlighter/dist/cjs/styles/prism'
import 'github-markdown-css/github-markdown.css'
import style from './markdown.module.css'
import { Box } from '@mui/material'

function MarkDownLayout({ markdown }) {
  return (
    <>
      {!!markdown ? (
        // <Box sx={{ p: '2rem' }}>
        <Box>
          <ReactMarkdown
            remarkPlugins={[gfm]}
            className={style.markdown}
            components={{
              code({ node, inline, className, children, ...props }) {
                const match = /language-(\w+)/.exec(className || '')
                return !inline && match ? (
                  <SyntaxHighlighter
                    style={prism}
                    language={match[1]}
                    PreTag="div"
                    {...props}
                  >
                    {String(children).replace(/\n$/, '')}
                  </SyntaxHighlighter>
                ) : (
                  <code className={className} {...props}>
                    {children}
                  </code>
                )
              },
            }}
          >
            {markdown}
          </ReactMarkdown>
        </Box>
      ) : (
        <div></div>
      )}
    </>
  )
}

export default MarkDownLayout
