export const headerTitle = {
  question: '質問',
  notes: 'ノート',
  search: '検索',
  faq: 'FAQ',
  inquiry: 'お問合せ',
  bug_fixer: 'バグ修正',
  refactoring: 'リファクタリング',
  generate_code: 'コード生成',
  generate_image: '画像生成',
  dummy_data: 'ダミーデータ',
  code_analysis: 'コード解析',
  code_conversion: 'コード変換',
  generate_resource: '教材の作成',
  shared_notes: '共有ノート',
  make_note: 'ノートの作成',
  // Admin
  'admin_dashboard/home': 'ホーム',
  'admin_dashboard/new_user': '新規ユーザー作成',
  'admin_dashboard/user_list': 'ユーザ一覧',
  'admin_dashboard/search_note': 'ノート検索',
  'admin_dashboard/all_notes': '全てのノート',
  'admin_dashboard/shared_notes': '共有ノート',
}

export const pathToHeaderTitle = (path) => {
  // パスが '/notes/' で始まる場合、ノート詳細として扱う
  if (path.startsWith('note_detail/')) {
    return 'ノート詳細'
  }
  if (path.startsWith('admin_dashboard/note_detail')) {
    return 'ノート詳細'
  }
  if (path.startsWith('admin_dashboard/user_detail')) {
    return 'ユーザー詳細'
  }

  // パスが '/users/' で始まる場合、ユーザー詳細として扱う
  if (path.startsWith('users/')) {
    return 'ユーザー詳細'
  }

  return headerTitle[path] || ''
}

export const pathToIsBackButtonVisible = (path) => {
  if (path.startsWith('note_detail/')) {
    return true
  }
  if (path.startsWith('admin_dashboard/note_detail')) {
    return true
  }
  if (path.startsWith('admin_dashboard/user_detail')) {
    return true
  }
  if (path.startsWith('admin_dashboard/search_note')) {
    return true
  }
  return false
}
