import { apiSlice } from '../../state/api/apiSlice'

export const usersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: (params) => {
        return {
          url: '/v1/users',
          params: {
            page: params?.page,
            pageSize: params?.pageSize,
            sort: params?.sort,
          },
          method: 'GET',
          validateStatus: (response, result) => {
            return response.status === 200 && !result.error
          },
        }
      },
      transformResponse: (responseData) => {
        const loadedUsers = responseData.result.map((user) => {
          user.id = user._id
          return user
        })
        responseData.result = loadedUsers
        return responseData
      },
      providesTags: (result, error, arg) => {
        // result is already transformed by transformResponse
        if (result?.result?.length > 0) {
          return [
            { type: 'User', id: 'LIST' },
            ...result.result.map((user) => ({
              type: 'User',
              id: user.id,
            })),
          ]
        } else return [{ type: 'User', id: 'LIST' }]
      },
    }),
    getUserById: builder.query({
      query: (userId) => ({
        url: `/v1/users/${userId}`,
        validateStatus: (response, result) => {
          return response.status === 200 && !result.error
        },
      }),
      transformResponse: (responseData) => {
        const loadedUser = responseData.result
        loadedUser.id = loadedUser._id
        responseData.result = loadedUser
        return responseData
      },
      providesTags: (result, error, arg) => [{ type: 'User', id: arg }],
    }),
    addNewUser: builder.mutation({
      query: (newUserData) => ({
        url: '/v1/users',
        method: 'POST',
        body: newUserData,
      }),
      invalidatesTags: [{ type: 'User', id: 'LIST' }],
    }),
    updateUser: builder.mutation({
      query: (initialUserData) => ({
        url: `/v1/users/${initialUserData.id}`,
        method: 'PATCH',
        body: {
          ...initialUserData,
        },
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'User', id: arg.id }],
    }),
    deleteUser: builder.mutation({
      query: (params) => ({
        url: `/v1/users/${params.userId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'User', id: 'LIST' }],
    }),
  }),
})

export const {
  useGetUsersQuery,
  useGetUserByIdQuery,
  useAddNewUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
} = usersApiSlice
