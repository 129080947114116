import React, { useEffect, useState } from 'react'
import { Alert, AlertTitle, Box, Button, TextField } from '@mui/material'
import { Formik } from 'formik'
import * as yup from 'yup'
import { useAddNewUserMutation } from './usersApiSlice'

const checkoutSchema = yup.object().shape({
  username: yup.string().required('required'),
  password: yup.string().required('required'),
  //   email: yup.string().email('invalid email').required('required'),
})
const initialValues = {
  username: '',
  password: '',
  //   email: '',
}

const NewUserForm = () => {
  const [addNewUser, { isSuccess }] = useAddNewUserMutation()

  const [isAlertVisible, setAlertVisible] = useState(false)

  const handleButtonClick = () => {
    setAlertVisible(true)
  }

  const handleCloseAlert = () => {
    setAlertVisible(false)
  }

  const handleFormSubmit = async (values, onSubmitProps) => {
    await addNewUser(values)
    handleButtonClick()
    onSubmitProps.resetForm()
  }

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        handleCloseAlert()
      }, 3000)
    }
  }, [isSuccess])

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValues}
      validationSchema={checkoutSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              '& > div': { gridColumn: undefined },
            }}
          >
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="ユーザ名"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.username}
              name="username"
              error={!!touched.username && !!errors.username}
              helperText={touched.username && errors.username}
              sx={{ gridColumn: 'span 2' }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="パスワード"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.password}
              name="password"
              error={!!touched.password && !!errors.password}
              helperText={touched.password && errors.password}
              sx={{ gridColumn: 'span 2' }}
            />
            {/* <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Email"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.email}
              name="email"
              error={!!touched.email && !!errors.email}
              helperText={touched.email && errors.email}
              sx={{ gridColumn: 'span 4' }}
            /> */}
          </Box>
          <Box display="flex" justifyContent="end" mt="20px">
            <Button type="submit" color="secondary" variant="contained">
              ユーザ作成
            </Button>
          </Box>
          {isAlertVisible && (
            <Alert severity="success" onClose={handleCloseAlert}>
              <AlertTitle>成功</AlertTitle>
              新しいユーザの作成に成功しました
            </Alert>
          )}
        </form>
      )}
    </Formik>
  )
}

export default NewUserForm
