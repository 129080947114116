import React, { useState } from 'react'
import {
  Alert,
  Button,
  Snackbar,
  TextField,
  Typography,
  Box,
} from '@mui/material'

import { useLazyGetNoteByShareIdQuery } from 'features/notes/notesApiSlice'
import MarkDownLayout from 'components/MarkDownLayout'

const Search = () => {
  const [shareId, setShareId] = useState('') // 共有IDを管理するためのstate
  const [note, setNote] = useState(null) // ノートデータを管理するためのstate
  const [trigger] = useLazyGetNoteByShareIdQuery({
    preferCacheValue: false,
  })

  const [open, setOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const showAlert = () => {
    setOpen(true)

    // 5秒後にアラートを閉じる
    setTimeout(() => {
      setOpen(false)
      setErrorMessage('')
    }, 5000)
  }

  const searchHandler = async () => {
    try {
      // ボタンが押されたらクエリを発行
      const result = await trigger({ shareId: shareId })
      const note = result.data.result[0] || null
      setNote(note)

      if (note) {
        // ノートデータが取得できたらセット
        // setNote(data.data)
        setNote(note)
      } else {
        setErrorMessage('ノートが見つかりませんでした')
        showAlert()
        setNote(null)
        // Alert not found
      }
    } catch (error) {
      console.error('検索エラー:', error)
    }
  }
  return (
    <Box width="100%" p="1.5rem">
      {/* <Typography variant="h5" mb="10px">
        検索
      </Typography> */}
      <TextField
        value={shareId}
        onChange={(event) => {
          setShareId(event.target.value)
        }}
        fullWidth
        label="共有ID"
        id="shareid"
      />
      <Box sx={{ mt: '1rem', display: 'flex', justifyContent: 'end' }}>
        <Button
          onClick={() => {
            searchHandler()
          }}
          variant="contained"
        >
          検索
        </Button>
      </Box>
      {!!note && (
        <Box>
          {/* 質問 */}
          <Typography variant="h5" mt="10px">
            [質問]
          </Typography>
          <MarkDownLayout markdown={note?.contentTitle} />
          {/*解答 */}
          <Typography variant="h5" mt="10px">
            [解答]
          </Typography>
          <MarkDownLayout markdown={note?.content} />
        </Box>
      )}
      <Snackbar
        open={open}
        autoHideDuration={6000} // アラートが自動的に閉じるまでの時間（ミリ秒）
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity="error" onClose={() => setOpen(false)}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default Search
