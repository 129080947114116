import { apiSlice } from '../../state/api/apiSlice'

export const usersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addNewAiQuestion: builder.mutation({
      query: (questionData) => ({
        url: '/v1/ai/question',
        method: 'POST',
        body: questionData,
      }),
      transformResponse: (responseData) => {
        const answer = responseData.result
        return answer
      },
    }),
    addNewImage: builder.mutation({
      query: (imageData) => ({
        url: '/v1/ai/images',
        method: 'POST',
        body: imageData,
      }),
      transformResponse: (responseData) => {
        const answer = responseData.result
        return answer
      },
    }),
  }),
})

export const { useAddNewAiQuestionMutation, useAddNewImageMutation } =
  usersApiSlice
