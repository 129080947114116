import React, { useEffect, useState } from 'react'
import { Alert, AlertTitle, Box, Button, TextField } from '@mui/material'
import { Formik } from 'formik'
import * as yup from 'yup'

import { useChangePasswordMutation } from './authApiSlice'

const checkoutSchema = yup.object().shape({
  currentPassword: yup.string().required('必須'),
  newPassword: yup.string().required('必須'),
  confirmPassword: yup.string().required('必須'),
})

const ChangePasswordForm = ({ user }) => {
  const [updatePassword, { isSuccess, isError }] = useChangePasswordMutation()

  const [isSuccessAlertVisible, setIsSuccessAlertVisible] = useState(false)

  const initialValues = {
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  }
  const [isDifferentPassword, setIsDifferentPassword] = useState(false)
  const [isCurrentPasswordError, setIsCurrentPasswordError] = useState(false)

  const handleCloseAlert = () => {
    setIsSuccessAlertVisible(false)
    setIsDifferentPassword(false)
    setIsCurrentPasswordError(false)
  }

  const handleFormSubmit = async (values, onSubmitProps) => {
    if (values.newPassword !== values.confirmPassword) {
      setIsDifferentPassword(true)
      return
    }

    await updatePassword({
      id: user.id,
      currentPassword: values.currentPassword,
      password: values.newPassword,
    })
    // handleButtonClick()
    // onSubmitProps.resetForm()
  }

  useEffect(() => {
    if (isDifferentPassword) {
      setTimeout(() => {
        handleCloseAlert()
      }, 3000)
      return
    }
    if (isSuccess) {
      setIsSuccessAlertVisible(true)
      setTimeout(() => {
        handleCloseAlert()
      }, 3000)
      return
    }
    if (isError) {
      setIsCurrentPasswordError(true)
      setTimeout(() => {
        handleCloseAlert()
      }, 3000)
      return
    }
  }, [isSuccess, isError, isDifferentPassword])

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValues}
      validationSchema={checkoutSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box
            display="grid"
            gap="30px"
            // gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              '& > div': { gridColumn: undefined },
            }}
          >
            <TextField
              fullWidth
              type="password"
              variant="filled"
              label="現在のパスワード"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.currentPassword}
              name="currentPassword"
              error={!!touched.currentPassword && !!errors.username}
              helperText={touched.currentPassword && errors.currentPassword}
              // sx={{ gridColumn: 'span 3' }}
            />
            <TextField
              fullWidth
              type="password"
              variant="filled"
              label="新しいパスワード"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.newPassword}
              name="newPassword"
              error={!!touched.newPassword && !!errors.newPassword}
              helperText={touched.newPassword && errors.newPassword}
              // sx={{ gridColumn: 'span 3' }}
            />
            <TextField
              fullWidth
              type="password"
              variant="filled"
              label="確認パスワード"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.confirmPassword}
              name="confirmPassword"
              error={!!touched.confirmPassword && !!errors.confirmPassword}
              helperText={touched.confirmPassword && errors.confirmPassword}
              // sx={{ gridColumn: 'span 3' }}
            />
          </Box>
          <Box display="flex" justifyContent="end" mt="20px">
            <Button type="submit" color="secondary" variant="contained">
              Update User
            </Button>
          </Box>
          {isSuccessAlertVisible && (
            <Alert severity="success" onClose={handleCloseAlert}>
              <AlertTitle>成功</AlertTitle>
              パスワードの変更に成功しました
            </Alert>
          )}
          {isDifferentPassword && (
            <Alert severity="error" onClose={handleCloseAlert}>
              <AlertTitle>Error</AlertTitle>
              新パスワードと確認パスワードが異なります —{' '}
              <strong>再度入力し直してください</strong>
            </Alert>
          )}
          {isCurrentPasswordError && (
            <Alert severity="error" onClose={handleCloseAlert}>
              <AlertTitle>Error</AlertTitle>
              現在のパスワードが間違っています —{' '}
              <strong>再度入力し直してください</strong>
            </Alert>
          )}
        </form>
      )}
    </Formik>
  )
}

export default ChangePasswordForm
