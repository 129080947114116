import { apiSlice } from '../../state/api/apiSlice'

export const usagesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUsages: builder.query({
      query: (params) => {
        return {
          url: '/v1/usages',
          params: {
            userId: params.userId,
            page: params?.page,
            pageSize: params?.pageSize,
            sort: params?.sort,
          },
          method: 'GET',
          validateStatus: (response, result) => {
            return response.status === 200 && !result.error
          },
          //TODO: change userId pass method from url params to body
        }
      },
      providesTags: (result, error, arg) => {
        // result is already transformed by transformResponse
        if (result?.ids) {
          return [
            { type: 'Usage', id: 'LIST' },
            ...result.ids.map((id) => ({ type: 'Usage', id })),
          ]
        } else return [{ type: 'Usage', id: 'LIST' }]
      },
    }),
  }),
})

export const { useGetUsagesQuery } = usagesApiSlice
