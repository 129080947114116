import React, { useState } from 'react'
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import {
  useDeleteNoteMutation,
  useLazyGetNoteByShareIdQuery,
} from 'features/notes/notesApiSlice'
import MarkDownLayout from 'components/MarkDownLayout'

const Search = () => {
  const [shareId, setShareId] = useState('') // 共有IDを管理するためのstate
  const [note, setNote] = useState(null) // ノートデータを管理するためのstate

  const [trigger] = useLazyGetNoteByShareIdQuery({
    preferCacheValue: false,
  })

  const [
    deleteNote,
    // {
    //   isLoading: isDeleting,
    //   isSuccess: isDeleteSuccess,
    //   isError: isDeleteError,
    //   error,
    // },
  ] = useDeleteNoteMutation()

  const [open, setOpen] = useState(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const showAlert = () => {
    setOpen(true)

    // 5秒後にアラートを閉じる
    setTimeout(() => {
      setOpen(false)
      setErrorMessage('')
    }, 5000)
  }

  const deleteButtonHandler = async () => {
    await deleteNote(note._id)
    setOpenDeleteDialog(false)
    setNote(null)
  }

  // const handleOpen = () => {
  //   setOpen(true)
  // }
  const handleClose = () => {
    setOpen(false)
  }

  const searchHandler = async () => {
    try {
      // ボタンが押されたらクエリを発行
      const result = await trigger({ shareId: shareId })

      const note = result.data.result[0] || null
      setNote(note)

      if (note) {
        // ノートデータが取得できたらセット
        // setNote(data.data)
        setNote(note)
      } else {
        setErrorMessage('ノートが見つかりませんでした')
        showAlert()
        setNote(null)
        // Alert not found
      }
    } catch (error) {
      console.error('検索エラー:', error)
    }
  }
  return (
    <Box width="100%" p="1.5rem">
      {/* <Typography variant="h5" mb="10px">
        検索
      </Typography> */}
      <TextField
        value={shareId}
        onChange={(event) => {
          setShareId(event.target.value)
        }}
        fullWidth
        label="共有ID"
        id="shareId"
      />
      <Box sx={{ mt: '1rem', display: 'flex', justifyContent: 'end' }}>
        <Button
          onClick={() => {
            searchHandler()
          }}
          variant="contained"
        >
          検索
        </Button>
      </Box>
      {!!note && (
        <Box>
          <Typography variant="h6" my="20px">
            作成者: {note?.userId?.username}
          </Typography>
          {/* 質問 */}
          <Typography variant="h5" mt="10px">
            [質問]
          </Typography>
          <MarkDownLayout markdown={note?.contentTitle} />
          {/*解答 */}
          <Typography variant="h5" mt="10px">
            [解答]
          </Typography>
          <MarkDownLayout markdown={note?.content} />
          <Divider sx={{ marginY: '1rem' }} />
          {/* 削除ボタン */}
          <Box sx={{ display: 'flex', justifyContent: 'end' }}>
            <Button
              variant="contained"
              onClick={() => {
                setOpenDeleteDialog(true)
              }}
            >
              <Typography variant="body2">削除</Typography>
            </Button>
          </Box>
        </Box>
      )}
      {/* Error 対象のノート無し */}
      <Snackbar
        open={open}
        autoHideDuration={6000} // アラートが自動的に閉じるまでの時間（ミリ秒）
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity="error" onClose={() => setOpen(false)}>
          {errorMessage}
        </Alert>
      </Snackbar>
      {/* 削除ダイアログ */}
      <Dialog
        open={openDeleteDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">ノート削除</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ノートの削除をしてもよろしいですか。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDeleteDialog(false)
            }}
          >
            キャンセル
          </Button>
          <Button onClick={deleteButtonHandler} autoFocus>
            削除
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default Search
