import React from 'react'
import { Box } from '@mui/material'

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const FAQ = () => {
  return (
    <Box sx={{ width: '100%', p: '1.5rem' }}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>1日何回まで質問できますか？</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>100回/1日使用することが可能です</Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>AIの解答は必ずあっていますか？</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            人間と同じように必ずしも正確な解答がかえってくるわけではありません。そのため、情報を精査する必要があります。また、質問の仕方を変える必要があります。
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>アプリのバグ or 改善点を見つけました</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>Slackの#改善点チャネルで報告お願いします</Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

export default FAQ
