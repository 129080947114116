import { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import useAuth from 'hooks/useAuth'
import ChangePasswordForm from 'features/auth/ChangePasswordForm'
import { useGetUserByIdQuery } from 'features/users/usersApiSlice'

const ChangePassword = () => {
  const { userId } = useAuth()
  const { data, isLoading: userLoading } = useGetUserByIdQuery(userId)
  const [user, setUser] = useState(undefined)

  useEffect(() => {
    if (data?.result) {
      setUser(data.result)
    }
  }, [data])

  if (userLoading || !user) {
    return <Box m="20px">Loading...</Box>
  }

  return (
    <Box m="20px">
      <ChangePasswordForm user={user} />
    </Box>
  )
}

export default ChangePassword
