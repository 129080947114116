import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import {
  Box,
  Button,
  Divider,
  Stack,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'

import { useGetNotesQuery } from 'features/notes/notesApiSlice'
import {
  useDeleteUserMutation,
  useGetUserByIdQuery,
} from 'features/users/usersApiSlice'
import { useGetUsagesQuery } from 'features/usages/usagesApiSlice'

import CustomDataGrid from 'components/CustomDataGrid'
import convertToJapanTime from 'utils/convertToJapanTime'

const AdminUserDetail = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const [open, setOpen] = useState(false)
  const [user, setUser] = useState(undefined)

  const [notes, setNotes] = useState([])
  const [sort, setSort] = useState({})
  const [rowCount, setRowCount] = useState(0)

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 20,
    page: 0,
  })

  const [usages, setUsages] = useState(undefined)
  const [usageSort, setUsageSort] = useState({})
  const [usagePaginationModel, setUsagePaginationModel] = useState({
    pageSize: 5,
    page: 0,
  })
  const [usageRowCount, setUsageRowCount] = useState(0)

  // const isSmallWindow = useMediaQuery('(max-width:800px)')

  const {
    data: noteData,
    isSuccess: isNoteSuccess,
    isLoading: noteLoading,
  } = useGetNotesQuery(
    // 'notesList',
    {
      userId: id,
      page: paginationModel.page,
      pageSize: paginationModel.pageSize,
      sort: JSON.stringify(sort),
    },
    {
      // useQueryの設定を追加
      refetchOnMountOrArgChange: true, // userIdもしくはisSharedが変更されたら再取得
      skip: !id, // userIdがない場合はskip
    }
  )

  const { data, isLoading: userLoading } = useGetUserByIdQuery(id)

  const {
    data: usagesData,
    isSuccess: isUsageSuccess,
    isLoading: usageLoading,
  } = useGetUsagesQuery(
    {
      userId: id,
      page: usagePaginationModel.page,
      pageSize: usagePaginationModel.pageSize,
      sort: JSON.stringify(usageSort),
    },
    {
      // useQueryの設定を追加
      refetchOnMountOrArgChange: true, // userIdもしくはisSharedが変更されたら再取得
      skip: !id, // userIdがない場合はskip
    }
  )

  const [deleteUser] = useDeleteUserMutation()

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const deleteButtonHandler = async () => {
    await deleteUser({ userId: id })
    navigate(`/admin_dashboard/user_list`)
  }

  useEffect(() => {
    if (data?.result) {
      setUser(data.result)
    }
  }, [data])

  useEffect(() => {
    if (isNoteSuccess) {
      const { result } = noteData

      if (result) {
        setNotes(noteData.result)
        setRowCount(noteData.count)
      }
    }
  }, [noteData]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isUsageSuccess) {
      const { result } = usagesData
      if (result) {
        setUsages(result)
        setUsageRowCount(usagesData.count)
      }
    }
  }, [usagesData]) // eslint-disable-line react-hooks/exhaustive-deps

  if (userLoading) {
    return <Box m="20px">Loading...</Box>
  }

  const columns = [
    {
      field: 'createdAt',
      headerName: '作成日',
      valueGetter: (params) => {
        return convertToJapanTime(params.row.createdAt) // 日本時間に変換
      },
    },
    {
      field: 'isShared',
      headerName: '共有',
      valueGetter: (params) => {
        return params.row.isShared ? '共有中' : '未'
      },
    },
    { field: 'contentTitle', headerName: 'Title', flex: 1 },
    {
      field: 'detail',
      headerName: 'Detail',
      renderCell: (params) => {
        return (
          <Stack spacing={0.25}>
            <Button
              onClick={() => {
                navigate(`/admin_dashboard/note_detail/${params.row._id}`)
              }}
              variant="contained"
            >
              詳細
            </Button>
          </Stack>
        )
      },
    },
  ]

  const usageGridColumns = [
    {
      field: 'date',
      headerName: 'DATE',
      valueGetter: (params) => convertToJapanTime(params.value),
    },
    { field: 'count', headerName: 'COUNT' },
  ]

  return (
    <Box p="20px">
      {/* UserId */}
      <Box sx={{ mb: '0.5rem' }}>
        <Typography variant="body1">UserId</Typography>
        <Typography variant="h4">{user?._id}</Typography>
      </Box>
      {/* Username */}
      <Box sx={{ mb: '0.5rem' }}>
        <Typography variant="body1">Username</Typography>
        <Typography variant="h4">{user?.username}</Typography>
      </Box>
      {/* Roles */}
      <Box sx={{ mb: '0.5rem' }}>
        <Typography variant="body1">Roles</Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          {!!user?.roles &&
            user.roles.map((role, index) => {
              const isLast = index === user.roles.length - 1
              const roleText = isLast ? role : `${role}, `
              return (
                <Typography variant="h4" key={role}>
                  {roleText}
                </Typography>
              )
            })}
        </Box>
      </Box>
      {/* Username */}
      <Box sx={{ mb: '0.5rem' }}>
        <Typography variant="body1">Active</Typography>
        <Typography variant="h4">{user?.active ? 'Yes' : 'No'}</Typography>
      </Box>

      {/*  */}
      {/* <EditUserForm user={user} /> */}
      <Divider sx={{ marginBottom: '1rem' }} />
      <Box sx={{ display: 'flex', justifyContent: 'end' }}>
        <Button onClick={handleOpen} color="secondary" variant="contained">
          削除
        </Button>
      </Box>
      {/* Usage */}
      <Typography variant="h4" mb="1rem">
        利用状況
      </Typography>
      {/*  */}

      {usageLoading ? (
        <Box m="20px">Usage Loading...</Box>
      ) : (
        <Box>
          <CustomDataGrid
            height="50vh"
            rowCount={usageRowCount}
            isLoading={usageLoading}
            data={usages}
            columns={usageGridColumns}
            sort={usageSort}
            setSort={setUsageSort}
            paginationModel={usagePaginationModel}
            setPaginationModel={setUsagePaginationModel}
          />
        </Box>
      )}
      {/*  */}
      <Divider sx={{ marginBottom: '1rem' }} />
      {/* User Notes */}
      <Typography variant="h4" mb="1rem">
        ノート
      </Typography>
      {noteLoading ? (
        <Box m="20px">Note Loading...</Box>
      ) : (
        <Box>
          <CustomDataGrid
            rowCount={rowCount}
            isLoading={noteLoading}
            data={notes}
            columns={columns}
            sort={sort}
            setSort={setSort}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
          />
        </Box>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">ユーザ削除</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ユーザーの削除をしてもよろしいですか。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={handleClose}>
            キャンセル
          </Button>
          <Button color="warning" onClick={deleteButtonHandler} autoFocus>
            削除
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default AdminUserDetail
