import moment from 'moment'

const convertToJapanTime = (date) => {
  // UTCを日本時間に変換
  const japanTime = moment.utc(date).utcOffset('+09:00')
  // yyyy-mm-dd形式にフォーマット
  const formattedDate = japanTime.format('YYYY-MM-DD')
  return formattedDate
}

export default convertToJapanTime
