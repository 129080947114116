import React from 'react'
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
  Divider,
} from '@mui/material'
import {
  ChevronRightOutlined,
  DarkMode,
  LightMode,
  ReceiptLongOutlined,
} from '@mui/icons-material'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import FlexBetween from './FlexBetween'
import NotesIcon from '@mui/icons-material/Notes'
import QuizIcon from '@mui/icons-material/Quiz'
import StoreIcon from '@mui/icons-material/Store'
import SavedSearchIcon from '@mui/icons-material/SavedSearch'
import useAuth from 'hooks/useAuth'
import CloseIcon from '@mui/icons-material/Close'
import LoopIcon from '@mui/icons-material/Loop'
import DescriptionIcon from '@mui/icons-material/Description'
import { useDispatch, useSelector } from 'react-redux'
import { setMode } from 'features/theme/themeSlice'
import MedicalServicesIcon from '@mui/icons-material/MedicalServices'
import ConstructionIcon from '@mui/icons-material/Construction'
import CodeIcon from '@mui/icons-material/Code'
import DatasetIcon from '@mui/icons-material/Dataset'
import SourceIcon from '@mui/icons-material/Source'
import MenuBookIcon from '@mui/icons-material/MenuBook'
import KeyIcon from '@mui/icons-material/Key'
import ShareIcon from '@mui/icons-material/Share'

const navItems = [
  {
    text: 'Divider',
  },
  {
    text: '質問',
    to: 'question',
    icon: <ReceiptLongOutlined />,
  },
  {
    text: 'バグの対応',
    to: 'bug_fixer',
    icon: <MedicalServicesIcon />,
  },
  {
    text: 'リファクタリング',
    to: 'refactoring',
    icon: <ConstructionIcon />,
  },
  {
    text: 'コード生成',
    to: 'generate_code',
    icon: <CodeIcon />,
  },
  // {
  //   text: '画像生成',
  //   to: 'generate_image',
  //   icon: <Image />,
  // },
  {
    text: 'ダミーデータ',
    to: 'dummy_data',
    icon: <DatasetIcon />,
  },
  {
    text: 'コード解析',
    to: 'code_analysis',
    icon: <SourceIcon />,
  },
  {
    text: 'コード変換',
    to: 'code_conversion',
    icon: <LoopIcon />,
  },
  {
    text: '教材の作成',
    to: 'generate_resource',
    icon: <MenuBookIcon />,
  },
  {
    text: 'Divider',
  },
  {
    text: '共有ノート一覧',
    to: 'shared_notes',
    icon: <ShareIcon />,
  },
  {
    text: '共有ノートの検索',
    to: 'search',
    icon: <SavedSearchIcon />,
  },
  {
    text: 'Divider',
  },
  {
    text: 'Myノート',
    to: 'notes',
    icon: <NotesIcon />,
  },
  {
    text: 'ノート作成',
    to: 'make_note',
    icon: <DescriptionIcon />,
  },
  {
    text: 'パスワードの変更',
    to: 'change_password',
    icon: <KeyIcon />,
  },
  {
    text: 'Divider',
  },
  {
    text: 'FAQ',
    to: 'faq',
    icon: <QuizIcon />,
  },
  {
    text: 'お問い合わせ',
    to: 'inquiry',
    icon: <StoreIcon />,
  },
  {
    text: 'Divider',
  },
]

const Sidebar = ({
  user,
  drawerWidth,
  isSidebarOpen,
  setIsSidebarOpen,
  isNonMobile,
}) => {
  const { pathname } = useLocation()
  const [active, setActive] = useState('')
  const navigate = useNavigate()
  const theme = useTheme()
  const { username } = useAuth()
  const dispatch = useDispatch()
  const mode = useSelector((state) => state.theme.mode)
  const isDark = mode === 'dark'

  useEffect(() => {
    setActive(pathname.substring(1))
  }, [pathname])

  return (
    <Box component="nav">
      {isSidebarOpen && (
        <Drawer
          open={isSidebarOpen}
          onClose={() => setIsSidebarOpen(false)}
          variant="persistent"
          anchor="left"
          sx={{
            width: drawerWidth,
            '& .MuiDrawer-paper': {
              color: theme.palette.secondary[200],
              background: theme.palette.background.alt,
              boxSixing: 'border-box',
              borderWidth: isNonMobile ? 0 : '2px',
              width: drawerWidth,
            },
          }}
        >
          <Box
            sx={{
              justifyContent: 'space-between',
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            }}
          >
            <Box width="100%">
              <Box m="1rem 0.5rem 0.5rem 1.5rem">
                <FlexBetween color={theme.palette.secondary.main}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '0.1rem',
                    }}
                  >
                    <Box
                      sx={{
                        alignItems: 'start',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Typography
                        variant="logo"
                        fontWeight="bold"
                        color="mono.flip"
                      >
                        NextOutput Help
                      </Typography>
                      {/* <Box
                        sx={{
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'end',
                        }}
                      >
                        <Typography
                          variant="h5"
                          fontWeight="bold"
                          color="mono.flip"
                        >
                          Help
                        </Typography>
                      </Box> */}
                    </Box>
                    <Typography variant="h6" color="mono.flip">
                      ユーザ名：{username}
                    </Typography>
                  </Box>
                  <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                    <CloseIcon />
                  </IconButton>
                </FlexBetween>
              </Box>
              <List>
                {navItems.map(({ text, to, icon }, index) => {
                  if (text === 'Divider') {
                    return <Divider key={index} />
                  }
                  if (!icon) {
                    return (
                      <Typography key={index} sx={{ m: '2.25rem 0 1rem 3rem' }}>
                        {text}
                      </Typography>
                    )
                  }
                  return (
                    <ListItem key={index} disablePadding>
                      <ListItemButton
                        onClick={() => {
                          navigate('/' + to)
                          setActive(to)
                        }}
                        sx={{
                          backgroundColor:
                            active === to
                              ? theme.palette.secondary[300]
                              : 'transparent',
                          color:
                            active === to
                              ? isDark
                                ? theme.palette.grey[800]
                                : theme.palette.grey[100]
                              : theme.palette.secondary[100],
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            ml: '2rem',
                            color:
                              active === to
                                ? isDark
                                  ? theme.palette.grey[800]
                                  : theme.palette.grey[100]
                                : theme.palette.secondary[100],
                          }}
                        >
                          {icon}
                        </ListItemIcon>
                        <ListItemText primary={text} />
                        {active === to && (
                          <ChevronRightOutlined sx={{ ml: 'auto' }} />
                        )}
                      </ListItemButton>
                    </ListItem>
                  )
                })}
              </List>
            </Box>
            {/* MODE CHANGE */}
            <IconButton onClick={() => dispatch(setMode())}>
              {theme.palette.mode === 'dark' ? (
                <DarkMode sx={{ fontSize: '25px' }} />
              ) : (
                <LightMode sx={{ color: '#000000', fontSize: '25px' }} />
              )}
            </IconButton>
          </Box>
        </Drawer>
      )}
    </Box>
  )
}

export default Sidebar
