import React, { useEffect, useState } from 'react'
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
// import AutoCloseAlert from 'components/AutoCloseAlert'
import { TextareaAutosize } from '@mui/base/TextareaAutosize'

import MarkDownLayout from 'components/MarkDownLayout'

import { useAddNewNoteMutation } from 'features/notes/notesApiSlice'
import { TEXTAREA_STORAGE_KEYS } from 'constants/textAreaLocalstorageKey'
import FlexBetween from 'components/FlexBetween'
const formatSelection = ['markdown', 'csv', 'json']

const TAGS = ['Airtable', 'Softr', 'Notion', 'Weweb', 'Xano']

const MakeNote = () => {
  const [textareaValue, setTextareaValue] = useState('')
  const [title, setTitle] = useState('')
  // const [aiOutputText, setAiOutputText] = useState('')
  // const [noteId, setNoteId] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const [tags, setTags] = useState([])

  const [addNewNote] = useAddNewNoteMutation()

  const [format, setFormat] = useState('markdown')
  const [fileName, setFileName] = useState('dummy')

  const [isSuccessAlertVisible, setSuccessAlertVisible] = useState(false)

  // const [isErrorAlertVisible, setErrorAlertVisible] = useState(false)

  const closeAlert = () => {
    // 5秒後にアラートを閉じる
    setTimeout(() => {
      setSuccessAlertVisible(false)
      setErrorMessage('')
    }, 5000)
  }

  const handleCloseAlert = () => {
    setSuccessAlertVisible(false)
    // setErrorAlertVisible(false)
  }

  const handleFormatChange = (event) => {
    const format = event.target.value
    setFormat(format)
  }

  const handleTitleChange = (event) => {
    const title = event.target.value
    setTitle(title)
  }

  const handleDownload = () => {
    let content, fullFileName

    if (format === 'csv') {
      content = textareaValue
      fullFileName = `${fileName}.csv`
    } else if (format === 'markdown') {
      content = textareaValue
      fullFileName = `${fileName}.md`
    } else if (format === 'json') {
      content = textareaValue
      fullFileName = `${fileName}.json`
    }

    const blob = new Blob([content], { type: 'text/plain' })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = fullFileName
    document.body.appendChild(a)
    a.click()
    window.URL.revokeObjectURL(url)
  }

  // save textarea content to local storage whenever it changes
  useEffect(() => {
    const storedValue = localStorage.getItem(
      TEXTAREA_STORAGE_KEYS.TEXTAREA_NOTE
    )
    if (storedValue !== null) {
      setTextareaValue(storedValue)
    }
  }, [])
  useEffect(() => {
    if (textareaValue !== '') {
      localStorage.setItem(TEXTAREA_STORAGE_KEYS.TEXTAREA_NOTE, textareaValue)
    }
  }, [textareaValue])

  useEffect(() => {
    if (isSuccessAlertVisible || errorMessage) {
      closeAlert()
    }
  }, [errorMessage, isSuccessAlertVisible])

  const handleFormSubmit = async () => {
    if (!title) {
      setErrorMessage('タイトルを入力してください')
      return
    }
    if (!textareaValue) {
      setErrorMessage('ノートの内容を入力してください')
      return
    }
    try {
      const data = await addNewNote({
        title: title,
        content: textareaValue,
        tags: tags,
      }).unwrap()
      if (!data) return
      setTitle('')
      setTextareaValue('')
      setSuccessAlertVisible(true)
      setTags([])
    } catch (error) {
      setErrorMessage(error?.data?.error.message)
      // setErrorAlertVisible(true)
    }
  }

  const handleTextareaChange = (event) => {
    setTextareaValue(event.target.value)
  }
  const handleFileNameChange = (event) => {
    setFileName(event.target.value)
  }
  const handleChange = (event) => {
    // setTagを使いtagを更新する
    setTags(event.target.value)
  }

  return (
    <Box width="100%" p="1.5rem">
      {/* テキストインプットエリア */}
      <TextareaAutosize
        id="note-title-textarea"
        name="note-title-textarea"
        aria-label="textarea"
        placeholder="タイトル"
        rowsmin={3}
        value={title}
        onChange={handleTitleChange}
        style={{
          width: '100%',
          height: '50px',
          padding: '10px',
          fontSize: '1.5rem',
          overflow: 'auto',
          resize: 'none',
        }}
      />
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <TextareaAutosize
          id="note-content-textarea"
          name="note-content-textarea"
          aria-label="textarea"
          placeholder="マークダウン形式で記入してください"
          rowsmin={3}
          value={textareaValue}
          onChange={handleTextareaChange}
          style={{
            width: format === 'markdown' ? '49%' : '100%',
            height: '60vh',
            padding: '10px',
            fontSize: '1rem',
            overflow: 'auto',
            resize: 'none',
          }}
        />

        {format === 'markdown' && (
          <Box
            width={'49%'}
            sx={{
              paddingX: '10px',
              border: 1,
              borderColor: 'grey',
              borderRadius: '3px',
            }}
          >
            <MarkDownLayout markdown={textareaValue} />
            {!!textareaValue || (
              <Typography variant="body1" color={'grey'} paddingTop={'10px'}>
                結果を表示
              </Typography>
            )}
          </Box>
        )}
      </Box>
      {/* ボタン */}
      <Box
        sx={{
          marginTop: '15px',
          display: 'flex',
          alignItems: 'center',
          // justifyContent: !!noteId ? 'space-between' : 'end',
          // mb: '10px',
          justifyContent: 'space-between',
        }}
      >
        <FlexBetween gap={'10px'} sx={{ alignItems: 'end' }}>
          <TextField
            label="ファイル名"
            value={fileName}
            onChange={(e) => {
              handleFileNameChange(e)
            }}
          />
          <Select
            value={format}
            label="フォーマット"
            onChange={(e) => {
              handleFormatChange(e)
            }}
            sx={{
              // gridColumn: 'span 2',
              minWidth: '100px',
            }}
          >
            {formatSelection.map((format) => (
              <MenuItem key={format} value={format}>
                {format}
              </MenuItem>
            ))}
          </Select>
          {/* <IconButton
            color="black"
            size="large"
            onClick={() => {
              handleDownload()
            }}
          >
            <Download />
          </IconButton> */}

          <Button size={'medium'} variant="contained" onClick={handleDownload}>
            ダウンロード
          </Button>
        </FlexBetween>
        {/* {!!noteId && (
          <Typography variant="body2">{'ノートID: ' + noteId}</Typography>
        )} */}
        <Box
          gap={'10px'}
          sx={{ display: 'flex', flexDirection: 'row', alignItems: 'end' }}
        >
          <FormControl sx={{ minWidth: 120 }}>
            <Select
              width="50%"
              name="tags"
              value={tags}
              multiple
              onChange={handleChange}
            >
              {TAGS.map((tag) => (
                <MenuItem key={tag} value={tag}>
                  {tag}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <Button
              variant="contained"
              onClick={handleFormSubmit}
              // disabled={isLoading}
            >
              {/* {isLoading ? <CircularProgress size={24} color="inherit" /> : '送信'} */}
              保存
            </Button>
          </Box>
        </Box>
      </Box>
      {isSuccessAlertVisible && (
        <Alert severity="success" onClose={handleCloseAlert}>
          <AlertTitle>成功</AlertTitle>
          新しいノートの作成に成功しました
        </Alert>
      )}
      {errorMessage && (
        <Alert severity="error" onClose={handleCloseAlert}>
          <AlertTitle>失敗</AlertTitle>
          {errorMessage}
        </Alert>
      )}
    </Box>
  )
}

export default MakeNote
