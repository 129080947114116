import { CssBaseline, ThemeProvider } from '@mui/material'
import { createTheme } from '@mui/material/styles'
import { useSelector } from 'react-redux'
import { useMemo } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { ROLES } from 'config/roles'
// Hooks
import useTitle from 'hooks/useTitle'
// Components
import AdminLayout from 'components/AdminLayout'
import Layout from 'components/Layout'
// Features
import RequireAuth from 'features/auth/RequireAuth'
import PersistLogin from 'features/auth/PersistLogin'
import { themeSettings } from 'features/theme/themeSetting'
// Normal Pages
import Question from 'pages/Question'
import Login from 'pages/Login'
import Notes from 'pages/Notes'
import NoteDetail from 'pages/NoteDetail'
import FAQ from 'pages/FAQ'
import Inquiry from 'pages/Inquiry'
import Search from 'pages/Search'
import SharedNotes from 'pages/SharedNotes'
import ChangePassword from 'pages/ChangePassword'
import BugFixer from 'pages/BugFixer'
import Refactoring from 'pages/Refactoring'
import GenerateCode from 'pages/GenerateCode'
import DummyData from 'pages/DummyData'
import CodeAnalysis from 'pages/CodeAnalysis'
import GenerateResource from 'pages/GenerateResource'
// Admin Pages
import AdminSearch from 'pages/admin_dashboard/Search'
import AdminSharedNotes from 'pages/admin_dashboard/AdminSharedNotes'
import AdminNoteDetail from 'pages/admin_dashboard/AdminNoteDetail'
import AdminHome from 'pages/admin_dashboard/AdminHome'
import AdminChangePassword from 'pages/admin_dashboard/AdminChangePassword'
import AdminUserDetail from 'pages/admin_dashboard/AdminUserDetail'
import AllNotes from 'pages/admin_dashboard/AllNotes'
import NewUser from 'pages/admin_dashboard/NewUser'
import EditUser from 'pages/admin_dashboard/EditUser'
import UserList from 'pages/admin_dashboard/UserList'
import MakeNote from 'pages/MakeNote'
import CodeConversion from 'pages/CodeConversion'
import GenerateImage from 'pages/GenerateImage'

function App() {
  const mode = useSelector((state) => state.theme.mode)
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode])
  useTitle('NextOutput Help')
  return (
    <div className="app">
      <BrowserRouter>
        {/* Mui Theme */}
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {/* Routes */}
          <Routes>
            <Route path="/" element={<Navigate to="/login" replace />} />
            <Route path="/login" element={<Login />} />

            {/* Protected Routes */}
            <Route element={<PersistLogin />}>
              {/* Student Routes */}
              <Route
                element={
                  <RequireAuth allowedRoles={[...Object.values(ROLES)]} />
                }
              >
                <Route element={<Layout />}>
                  {/* <Route path="/home" element={<Navigate to="/question" />} /> */}
                  <Route path="/question" element={<Question />} />
                  <Route path="/bug_fixer" element={<BugFixer />} />
                  <Route path="/refactoring" element={<Refactoring />} />
                  <Route path="/generate_code" element={<GenerateCode />} />
                  {/* <Route path="/generate_image" element={<GenerateImage />} /> */}
                  <Route path="/code_analysis" element={<CodeAnalysis />} />
                  <Route path="/code_conversion" element={<CodeConversion />} />
                  <Route path="/dummy_data" element={<DummyData />} />
                  <Route
                    path="/generate_resource"
                    element={<GenerateResource />}
                  />
                  <Route path="/notes" element={<Notes />} />
                  <Route path="/note_detail/:id" element={<NoteDetail />} />
                  <Route path="/make_note" element={<MakeNote />} />
                  <Route path="/faq" element={<FAQ />} />
                  <Route path="/inquiry" element={<Inquiry />} />
                  <Route path="/search" element={<Search />} />
                  <Route path="/shared_notes" element={<SharedNotes />} />
                  <Route path="/change_password" element={<ChangePassword />} />
                </Route>
              </Route>
              {/* Admin Routes */}
              <Route element={<RequireAuth allowedRoles={ROLES.Admin} />}>
                <Route path="/admin_dashboard" element={<AdminLayout />}>
                  <Route path="home" element={<AdminHome />} />
                  <Route path="new_user" element={<NewUser />} />
                  <Route path="user_list" element={<UserList />} />
                  <Route path="edit_user/:id" element={<EditUser />} />
                  <Route
                    path="change_password/:id"
                    element={<AdminChangePassword />}
                  />
                  <Route path="user_detail/:id" element={<AdminUserDetail />} />
                  <Route path="note_detail/:id" element={<AdminNoteDetail />} />
                  <Route path="search_note" element={<AdminSearch />} />
                  <Route path="all_notes" element={<AllNotes />} />
                  <Route path="shared_notes" element={<AdminSharedNotes />} />
                </Route>
              </Route>
            </Route>

            {/* Not Found */}
            <Route path="*" element={<Navigate to="/login" replace />} />
          </Routes>

          {/* Routes End */}
        </ThemeProvider>
      </BrowserRouter>
    </div>
  )
}

export default App
