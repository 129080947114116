import React from 'react'
import { Box, Typography } from '@mui/material'

const Inquiry = () => {
  return (
    <Box
      width={'100%'}
      sx={{
        p: '1.5rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography variant="h3">お問い合わせ先</Typography>
      <Box
        sx={{
          flexDirection: 'row',
          display: 'flex',
          marginTop: '1rem',
          padding: '1rem',
        }}
      >
        <Box>
          <Typography variant="h5">塾生の方・・・</Typography>
          {/* <Typography variant="h5">スタッフの方・・・</Typography> */}
        </Box>

        <Box>
          <Typography variant="h5">Slackのアカウント宛</Typography>
          {/* <Typography variant="h5">スタッフ用</Typography> */}
        </Box>
      </Box>
    </Box>
  )
}

export default Inquiry
