import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Box } from '@mui/material'

import AdminChangePasswordForm from 'features/auth/AdminChangePasswordForm'
import { useGetUserByIdQuery } from 'features/users/usersApiSlice'

const AdminChangePassword = () => {
  const { id } = useParams()
  const { data, isLoading: userLoading } = useGetUserByIdQuery(id)
  const [user, setUser] = useState(undefined)

  useEffect(() => {
    if (data?.result) {
      setUser(data.result)
    }
  }, [data])

  if (userLoading || !user) {
    return <Box m="20px">Loading...</Box>
  }

  return (
    <Box m="20px">
      <AdminChangePasswordForm user={user} />
    </Box>
  )
}

export default AdminChangePassword
