export const aiReqType = {
  STANDARD: 'STANDARD',
  DEBUG: 'DEBUG',
  REFACTORING: 'REFACTORING',
  GENERATE_CODE: 'GENERATE_CODE',
  DUMMY_DATA: 'DUMMY_DATA',
  CODE_ANALYSIS: 'CODE_ANALYSIS',
  CODE_CONVERSION: 'CODE_CONVERSION',
  GENERATE_RESOURCE: 'GENERATE_RESOURCE',
  HUMAN_NOTE: 'HUMAN_NOTE',
}

export const aiReqTypeToCaption = {
  [aiReqType.STANDARD]: '質問',
  [aiReqType.DEBUG]: 'デバック',
  [aiReqType.REFACTORING]: 'リファクタリング',
  [aiReqType.GENERATE_CODE]: 'コード',
  [aiReqType.DUMMY_DATA]: 'ダミーデータ',
  [aiReqType.CODE_ANALYSIS]: 'コード解析',
  [aiReqType.GENERATE_RESOURCE]: '教材',
  [aiReqType.HUMAN_NOTE]: 'ノート',
}
