import { useTheme } from '@mui/material'
import React, { useEffect } from 'react'
import { AppBar, Toolbar, Typography, IconButton } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import { useLocation, useNavigate } from 'react-router-dom'
import LogoutIcon from '@mui/icons-material/Logout'
import { useSendLogoutMutation } from 'features/auth/authApiSlice'
import FlexBetween from './FlexBetween'
import useAuth from 'hooks/useAuth'
import {
  pathToHeaderTitle,
  pathToIsBackButtonVisible,
} from 'constants/headerTitle'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { useDispatch } from 'react-redux'
import { setLightMode } from 'features/theme/themeSlice'
import { refreshAllTextArea } from 'constants/textAreaLocalstorageKey'

const MainBar = ({ isSidebarOpen, setIsSidebarOpen }) => {
  const theme = useTheme()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [sendLogout, { isSuccess }] = useSendLogoutMutation()

  const { isAdmin } = useAuth()

  useEffect(() => {
    if (isSuccess) navigate('/login')
  }, [isSuccess, navigate])

  const toggleHandler = () => {
    setIsSidebarOpen(!isSidebarOpen)
  }

  const logoutHandler = async () => {
    await refreshAllTextArea()
    sendLogout()
    dispatch(setLightMode())
  }

  const admin_dashboardAccessHandler = () => {
    navigate('/admin_dashboard/home')
  }

  return (
    <AppBar
      open={isSidebarOpen}
      sx={{
        position: 'static',
        background: 'none',
        boxShadow: 'none',
      }}
    >
      <Toolbar
        sx={{
          backgroundColor: '#1C225A',
          justifyContent: 'space-between',
        }}
      >
        {/* 左 */}
        <FlexBetween sx={{ gap: 3 }}>
          <IconButton
            aria-label="open drawer"
            onClick={toggleHandler}
            edge="start"
            sx={{
              mr: 2,
              ...(isSidebarOpen && { display: 'none' }),
              color: theme.palette.grey[100],
            }}
          >
            <MenuIcon />
          </IconButton>
          <FlexBetween>
            {pathToIsBackButtonVisible(pathname.substring(1)) && (
              <IconButton
                aria-label="open drawer"
                onClick={() => window.history.back()}
                edge="start"
                sx={{
                  color: theme.palette.grey[100],
                }}
              >
                <ArrowBackIosIcon />
              </IconButton>
            )}
            <Typography
              variant="h4"
              noWrap
              component="div"
              sx={{ color: theme.palette.grey[100] }}
            >
              {pathToHeaderTitle(pathname.substring(1))}
            </Typography>
          </FlexBetween>
        </FlexBetween>
        {/* 右 */}
        <FlexBetween sx={{ gap: 3 }}>
          {isAdmin && (
            <IconButton
              color="white"
              aria-label="open drawer"
              onClick={admin_dashboardAccessHandler}
              edge="end"
              sx={{ ml: 'auto', color: theme.palette.grey[100] }}
            >
              <ManageAccountsIcon />
            </IconButton>
          )}

          <IconButton
            color="white"
            aria-label="open drawer"
            onClick={logoutHandler}
            edge="end"
            sx={{ ml: 'auto', color: theme.palette.grey[100] }}
          >
            <LogoutIcon />
          </IconButton>
        </FlexBetween>
      </Toolbar>
    </AppBar>
  )
}

export default MainBar
