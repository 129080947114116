import React from 'react'
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material'
import {
  ChevronRightOutlined,
  DarkMode,
  HomeOutlined,
  LightMode,
  Notes,
} from '@mui/icons-material'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import FlexBetween from './FlexBetween'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import PeopleIcon from '@mui/icons-material/People'
import SavedSearchIcon from '@mui/icons-material/SavedSearch'
import ShareIcon from '@mui/icons-material/Share'
import useAuth from 'hooks/useAuth'
import CloseIcon from '@mui/icons-material/Close'
import { useDispatch, useSelector } from 'react-redux'
import { setMode } from 'features/theme/themeSlice'

const navItems = [
  {
    text: 'ホーム',
    to: 'admin_dashboard/home',
    icon: <HomeOutlined />,
  },
  {
    text: '新規ユーザの作成',
    to: 'admin_dashboard/new_user',
    icon: <PersonAddIcon />,
  },
  {
    text: 'ユーザ一覧',
    to: 'admin_dashboard/user_list',
    icon: <PeopleIcon />,
  },
  {
    text: '全てのノート',
    to: 'admin_dashboard/all_notes',
    icon: <Notes />,
  },
  {
    text: '共有ノートを検索',
    to: 'admin_dashboard/search_note',
    icon: <SavedSearchIcon />,
  },
  {
    text: '共有ノート一覧',
    to: 'admin_dashboard/shared_notes',
    icon: <ShareIcon />,
  },
]

const AdminSidebar = ({
  user,
  drawerWidth,
  isSidebarOpen,
  setIsSidebarOpen,
  isNonMobile,
}) => {
  const { pathname } = useLocation()
  const [active, setActive] = useState('')
  const navigate = useNavigate()
  const theme = useTheme()
  const { username } = useAuth()
  const dispatch = useDispatch()
  const mode = useSelector((state) => state.theme.mode)
  const isDark = mode === 'dark'
  useEffect(() => {
    setActive(pathname.substring(1))
  }, [pathname])

  return (
    <Box component="nav">
      {isSidebarOpen && (
        <Drawer
          open={isSidebarOpen}
          onClose={() => setIsSidebarOpen(false)}
          variant="persistent"
          anchor="left"
          sx={{
            width: drawerWidth,
            '& .MuiDrawer-paper': {
              color: theme.palette.secondary[200],
              background: theme.palette.secondary[700],
              boxSixing: 'border-box',
              borderWidth: isNonMobile ? 0 : '2px',
              width: drawerWidth,
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              justifyContent: 'space-between',
            }}
          >
            <Box width="100%">
              <Box m="1rem 0.5rem 1rem 1.5rem">
                <FlexBetween color={theme.palette.grey.main}>
                  <Box alignItems="center" gap="0.5rem">
                    <Typography
                      variant="logo"
                      fontWeight="bold"
                      color="mono.main"
                      // sx={{
                      //   color: isDark ? 'white' : 'black',
                      // }}
                    >
                      NextOutput Help
                    </Typography>
                    <Typography variant="h6" fontWeight="bold">
                      ( {username} )
                    </Typography>
                  </Box>
                  <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                    <CloseIcon />
                  </IconButton>
                </FlexBetween>
              </Box>

              <List>
                {navItems.map(({ text, icon, to }) => {
                  if (!icon) {
                    return (
                      <Typography key={text} sx={{ m: '2.25rem 0 1rem 3rem' }}>
                        {text}
                      </Typography>
                    )
                  }
                  return (
                    <ListItem key={text} disablePadding>
                      <ListItemButton
                        onClick={() => {
                          navigate('/' + to)
                          setActive(to)
                        }}
                        sx={{
                          backgroundColor:
                            active === to
                              ? theme.palette.secondary[300]
                              : 'transparent',
                          color:
                            active === to
                              ? isDark
                                ? theme.palette.grey[100]
                                : theme.palette.grey[100]
                              : theme.palette.secondary[100],
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            ml: '2rem',
                            color:
                              active === to
                                ? theme.palette.grey[100]
                                : theme.palette.secondary[200],
                          }}
                        >
                          {icon}
                        </ListItemIcon>
                        <ListItemText primary={text} />
                        {active === to && (
                          <ChevronRightOutlined sx={{ ml: 'auto' }} />
                        )}
                      </ListItemButton>
                    </ListItem>
                  )
                })}
              </List>
            </Box>
            {/* MODE CHANGE */}
            <IconButton onClick={() => dispatch(setMode())}>
              {theme.palette.mode === 'dark' ? (
                <DarkMode sx={{ fontSize: '25px' }} />
              ) : (
                <LightMode sx={{ color: '#000000', fontSize: '25px' }} />
              )}
            </IconButton>
          </Box>
        </Drawer>
      )}
    </Box>
  )
}

export default AdminSidebar
