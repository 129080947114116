import themeReducer from 'features/theme/themeSlice'
import { configureStore } from '@reduxjs/toolkit'
import { apiSlice } from './api/apiSlice'
import authReducer from '../features/auth/authSlice'
import checkIsProductionMode from 'utils/checkIsProductionMode'
// import { setupListeners } from '@reduxjs/toolkit/query'

const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    theme: themeReducer,
    auth: authReducer,
  },
  // Add the apiSlice reducer as a top-level reducer
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
  devTools: checkIsProductionMode() ? false : true, // if production mode, set to false
})
// setupListeners(store.dispatch)

export default store
