import { Box } from '@mui/material'
import NewUserForm from 'features/users/NewUserForm'

const NewUser = () => {
  return (
    <Box m="20px">
      <NewUserForm />
    </Box>
  )
}

export default NewUser
