import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Box } from '@mui/material'
// Features
import EditUserForm from 'features/users/EditUserForm'
import { useGetUserByIdQuery } from 'features/users/usersApiSlice'

const EditUser = () => {
  const { id } = useParams()
  const { data, isLoading: userLoading } = useGetUserByIdQuery(id)
  const [user, setUser] = useState(undefined)

  useEffect(() => {
    if (data?.result) {
      setUser(data.result)
    }
  }, [data])

  if (userLoading || !user) {
    return <Box m="20px">Loading...</Box>
  }

  return (
    <Box m="20px">
      <EditUserForm user={user} />
    </Box>
  )
}

export default EditUser
