import { Box, Typography, useTheme, useMediaQuery } from '@mui/material'
import LoginForm from 'features/auth/LoginForm'

const Login = () => {
  const theme = useTheme()
  const isNonMobileScreens = useMediaQuery('(min-width: 1000px)')
  return (
    <Box
      height="100vh"
      width="100%"
      display="flex"
      alignItems="center" // 垂直方向の中央寄せ
      justifyContent="center" // 水平方向の中央寄せ
      backgroundColor={theme.palette.grey[400]}
    >
      <Box
        width={isNonMobileScreens ? '50%' : '93%'}
        p="2rem"
        m="2rem auto"
        borderRadius="0.5rem"
        backgroundColor={theme.palette.grey[100]}
        boxShadow="0px 4px 6px rgba(0, 0, 0, 0.1)" // 影を追加するスタイル
        padding="20px" // 影が要素のサイズを変えないように余白を追加
      >
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography
            fontWeight="500"
            variant="logo"
            sx={{ mb: '1.5rem', color: theme.palette.grey[900] }}
          >
            NextOutput Help
          </Typography>
        </Box>
        <LoginForm />
      </Box>
    </Box>
  )
}

export default Login
