import { Outlet, useNavigate } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import { useRefreshMutation } from './authApiSlice'
import usePersist from '../../hooks/usePersist'
import { useSelector } from 'react-redux'
import { selectCurrentToken } from './authSlice'

// this component is in use for refreshing browser
// when the browser is refreshed, the token is lost
const PersistLogin = () => {
  const [persist] = usePersist()
  const token = useSelector(selectCurrentToken)
  const effectRan = useRef(false)
  const navigate = useNavigate()

  const [trueSuccess, setTrueSuccess] = useState(false)

  const [refresh, { isUninitialized, isLoading, isSuccess, isError, error }] =
    useRefreshMutation()

  useEffect(() => {
    if (effectRan.current === true || process.env.NODE_ENV !== 'development') {
      // React 18 Strict Mode only happened in development mode
      // prevent useEffect from running twice in development mode
      const verifyRefreshToken = async () => {
        try {
          //const response =
          await refresh()
          //const { accessToken } = response.data
          setTrueSuccess(true)
        } catch (err) {
          console.error(err)
        }
      }
      if (!token && persist) verifyRefreshToken()
    }

    return () => (effectRan.current = true)
    // want to run this effect only once after the component is unmounted
    // react 18 strict mode will run this effect twice
    // 1. useEffect
    // 2. cleanup (unmount)
    // 3. useEffect
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    if (isError) {
      navigate('/login')
    }
  }, [isError, navigate])

  let content
  if (!persist) {
    // persist: no
    content = <Outlet />
  } else if (isLoading) {
    //persist: yes, token: no
    content = <p>Loading...</p>
  } else if (isError) {
    //persist: yes, token: no
    content = <p className="errmsg">{error.data?.message}</p>
  } else if (isSuccess && trueSuccess) {
    //persist: yes, token: yes
    content = <Outlet />
  } else if (token && isUninitialized) {
    //persist: yes, token: yes
    content = <Outlet />
  }

  return content
}
export default PersistLogin
