import React, { useState, useEffect } from 'react'
import { Typography } from '@mui/material'

const BlinkingText = ({ text, interval }) => {
  const [isVisible, setIsVisible] = useState(true)

  useEffect(() => {
    const timer = setInterval(() => {
      setIsVisible((prevVisible) => !prevVisible)
    }, interval)

    return () => {
      clearInterval(timer)
    }
  }, [interval])

  return (
    <Typography
      variant="h6"
      style={{ visibility: isVisible ? 'visible' : 'hidden', opacity: 0.8 }}
    >
      {text}
    </Typography>
  )
}

export default BlinkingText
