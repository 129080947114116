import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Box, Button, Chip, Stack } from '@mui/material'

import CustomDataGrid from 'components/CustomDataGrid'
import FlexBetween from 'components/FlexBetween'

import { useGetUsersQuery } from 'features/users/usersApiSlice'

const UserList = () => {
  const [sort, setSort] = useState({})
  const [rowCount, setRowCount] = useState(0)
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 20,
    page: 0,
  })

  const { data, isLoading } = useGetUsersQuery(
    {
      page: paginationModel.page,
      pageSize: paginationModel.pageSize,
      sort: JSON.stringify(sort),
    },
    {
      refetchOnMountOrArgChange: true,
    }
  )

  const [users, setUsers] = useState([])

  const navigate = useNavigate()

  useEffect(() => {
    if (data?.result) {
      setUsers(data.result)
      setRowCount(data.count || 0)
    }
  }, [data])

  const editButtonHandler = (id) => {
    navigate(`/admin_dashboard/edit_user/${id}`)
  }

  const changePasswordButtonHandler = (id) => {
    navigate(`/admin_dashboard/change_password/${id}`)
  }

  const userDetailButtonHandler = (id) => {
    navigate(`/admin_dashboard/user_detail/${id}`)
  }

  if (isLoading) return <p>Loading...</p>

  if (isLoading) return <p>Loading...</p>

  const columns = [
    { field: 'username', headerName: 'ユーザ名', flex: 0.5 },
    {
      field: 'role',
      headerName: '権限',
      width: 350,
      //   type: 'singleSelect',
      //   valueOptions: [...new Set(userData.map((o) => o.role).flat())],
      renderCell: (params) => (
        <FlexBetween gap="2rem" width="300px">
          <Stack direction="row" spacing={0.25}>
            {params.row.roles.map((role) => (
              <Chip label={role} key={role} />
            ))}
          </Stack>
          <Button
            onClick={() => {
              editButtonHandler(params.row._id)
            }}
            variant="contained"
          >
            編集
          </Button>
        </FlexBetween>
      ),
    },
    {
      field: 'password',
      headerName: 'パスワード',
      width: 150,
      //   type: 'singleSelect',
      //   valueOptions: [...new Set(userData.map((o) => o.role).flat())],
      renderCell: (params) => {
        return (
          <Stack spacing={0.25}>
            <Button
              onClick={() => {
                changePasswordButtonHandler(params.row._id)
              }}
              variant="contained"
            >
              変更
            </Button>
          </Stack>
        )
      },
    },
    {
      field: 'detail',
      headerName: '詳細',

      //   type: 'singleSelect',
      //   valueOptions: [...new Set(userData.map((o) => o.role).flat())],
      renderCell: (params) => {
        return (
          <Stack spacing={0.25}>
            <Button
              onClick={() => {
                userDetailButtonHandler(params.row._id)
              }}
              variant="contained"
            >
              詳細
            </Button>
          </Stack>
        )
      },
    },
  ]

  return (
    <Box m="1.5rem 2.5rem">
      <CustomDataGrid
        rowCount={rowCount}
        isLoading={isLoading}
        data={users}
        columns={columns}
        sort={sort}
        setSort={setSort}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
      />
    </Box>
  )
}

export default UserList
