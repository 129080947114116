import { useSelector } from 'react-redux'
import { selectCurrentToken } from '../features/auth/authSlice'
import jwtDecode from 'jwt-decode'

const useAuth = () => {
  const token = useSelector(selectCurrentToken)
  let status = 'Employee' // default status
  let isStaff = false
  let isAdmin = false

  if (token) {
    const decoded = jwtDecode(token)
    const { username, roles, userId } = decoded.UserInfo

    isStaff = roles.includes('Staff')
    isAdmin = roles.includes('Admin')

    if (isStaff) status = 'Staff'
    if (isAdmin) status = 'Admin'

    return { username, roles, status, isStaff, isAdmin, userId }
  }

  return { username: '', roles: [], isStaff, isAdmin, status, userId: '' }
}
export default useAuth
