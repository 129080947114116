import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Box, Button, Chip, Stack } from '@mui/material'

import CustomDataGrid from 'components/CustomDataGrid'
import { aiReqTypeToCaption } from 'constants/aiReqType'

import { useGetNotesQuery } from 'features/notes/notesApiSlice'

import convertToJapanTime from 'utils/convertToJapanTime'
import FlexBetween from 'components/FlexBetween'

const AllNotes = () => {
  const navigate = useNavigate()
  const [sort, setSort] = useState({})
  const [rowCount, setRowCount] = useState(0)
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 20,
    page: 0,
  })
  const [notes, setNotes] = useState(undefined)

  const { data: noteData, isLoading: noteLoading } = useGetNotesQuery(
    // 'notesList',
    {
      page: paginationModel.page,
      pageSize: paginationModel.pageSize,
      sort: JSON.stringify(sort),
    },
    {
      // useQueryの設定を追加
      refetchOnMountOrArgChange: true, // userIdもしくはisSharedが変更されたら再取得
    }
  )

  useEffect(() => {
    if (noteData?.result) {
      setNotes(noteData.result)
      setRowCount(noteData.count || 0)
    }
  }, [noteData])

  const columns = [
    {
      field: 'createdAt',
      headerName: '作成日',

      valueGetter: (params) => {
        return convertToJapanTime(params.row.createdAt) // 日本時間に変換
      },
    },
    {
      field: 'userId',
      headerName: '作成者',
      valueGetter: (params) => {
        return params.row.userId?.username || ''
      },
    },
    {
      field: 'isShared',
      headerName: '共有',
      valueGetter: (params) => {
        return params.row.isShared ? '共有中' : '未'
      },
    },
    {
      field: 'contentCategory',
      headerName: 'カテゴリー',
      valueGetter: (params) => {
        const category = aiReqTypeToCaption[params.row.contentCategory] || ''
        return category
      },
    },
    {
      field: 'tags',
      headerName: 'タグ',
      width: 150,
      //   type: 'singleSelect',
      //   valueOptions: [...new Set(userData.map((o) => o.role).flat())],
      renderCell: (params) => (
        <FlexBetween gap="2rem" width="300px">
          {params.row.tags && (
            <Stack direction="row" spacing={0.25}>
              {params.row.tags.map((tag) => (
                <Chip label={tag} key={tag} />
              ))}
            </Stack>
          )}
        </FlexBetween>
      ),
    },

    { field: 'contentTitle', headerName: 'タイトル', flex: 1 },
    {
      field: 'detail',
      headerName: '詳細',
      renderCell: (params) => {
        return (
          <Stack spacing={0.25}>
            <Button
              onClick={() => {
                navigate(`/admin_dashboard/note_detail/${params.row._id}`)
              }}
              variant="contained"
            >
              詳細
            </Button>
          </Stack>
        )
      },
    },
  ]

  if (noteLoading) {
    return <Box m="20px">Loading...</Box>
  }

  return (
    <Box width="100%" p="1.5rem">
      <CustomDataGrid
        rowCount={rowCount}
        isLoading={noteLoading}
        data={notes}
        columns={columns}
        sort={sort}
        setSort={setSort}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
      />
    </Box>
  )
}

export default AllNotes
