import React, { useState } from 'react'
import { Box, useMediaQuery } from '@mui/material'
import { Outlet } from 'react-router-dom'
import Sidebar from './Sidebar'
import MainBar from './MainBar'

const Layout = () => {
  const isNonMobile = useMediaQuery('(min-width:600px)')
  const [isSidebarOpen, setIsSidebarOpen] = useState(true)
  return (
    // <Box display={isNonMobile ? 'flex' : 'block'} width="100%" height="100%">
    <Box display="flex" width="100%" height="100%">
      {/* SideBar */}
      <Sidebar
        isNonMobile={isNonMobile}
        drawerWidth="250px"
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
      />
      {/* Main */}
      <Box flexGrow={1}>
        <MainBar
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
        />
        <Outlet />
      </Box>
    </Box>
  )
}

export default Layout
