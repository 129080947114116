import { createSlice } from '@reduxjs/toolkit'

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    accessToken: null,
    refreshToken: localStorage.getItem('refreshToken') || null,
  },
  reducers: {
    setCredentials: (state, action) => {
      const { accessToken, refreshToken } = action.payload
      state.accessToken = accessToken
      state.refreshToken = refreshToken
      localStorage.setItem('refreshToken', refreshToken)
    },
    logOut: (state, action) => {
      state.accessToken = null
      state.refreshToken = null
      localStorage.removeItem('refreshToken')
    },
    setAccessToken: (state, action) => {
      state.accessToken = action.payload
    },
    setRefreshToken: (state, action) => {
      state.refreshToken = action.payload
      localStorage.setItem('refreshToken', action.payload)
    },
  },
})

export const { setCredentials, logOut, setAccessToken, setRefreshToken } =
  authSlice.actions

export default authSlice.reducer

export const selectCurrentToken = (state) => state.auth.accessToken

export const selectCurrentRefreshToken = (state) => state.auth.refreshToken
