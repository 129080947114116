import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { logOut, setAccessToken } from 'features/auth/authSlice'
import checkIsProductionMode from 'utils/checkIsProductionMode'

const baseQuery = fetchBaseQuery({
  baseUrl: checkIsProductionMode()
    ? process.env.REACT_APP_API_URL
    : process.env.REACT_APP_API_URL_LOCAL,
  credentials: 'include',
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.accessToken
    if (token) {
      headers.set('authorization', `Bearer ${token}`)
    }
    return headers
  },
})

const baseQueryWithRefreshToken = fetchBaseQuery({
  baseUrl: checkIsProductionMode()
    ? process.env.REACT_APP_API_URL
    : process.env.REACT_APP_API_URL_LOCAL,
  credentials: 'include',
  prepareHeaders: (headers, { getState }) => {
    const refreshToken = getState().auth.refreshToken
    if (refreshToken) {
      headers.set('authorization', `RefreshToken ${refreshToken}`)
    }
    return headers
  },
})

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions)

  if (result?.error?.status === 403) {
    const refreshResult = await baseQueryWithRefreshToken(
      'v1/auth/refresh',
      api,
      extraOptions
    )
    const token = refreshResult.data?.token

    if (token) {
      // store the new token
      api.dispatch(setAccessToken(token))

      // retry original query with new access token
      result = await baseQuery(args, api, extraOptions)
    } else {
      if (refreshResult?.error?.status === 403) {
        refreshResult.error.data.message = 'Your login has expired. '
      }
      if (refreshResult?.error?.status === 500) {
        refreshResult.error.data.message = 'Refresh token is Expired.'
        api.dispatch(logOut())
      }
      return refreshResult
    }
  }

  return result
}

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: ['User'],
  endpoints: (builder) => ({}),
})
