import { apiSlice } from '../../state/api/apiSlice'

export const notesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addNewNote: builder.mutation({
      query: (newNoteData) => ({
        url: '/v1/notes',
        method: 'POST',
        body: newNoteData,
      }),
      invalidatesTags: [{ type: 'User', id: 'LIST' }],
    }),
    getNotes: builder.query({
      query: (params) => {
        return {
          url: '/v1/notes',
          params: {
            userId: params?.userId,
            page: params?.page,
            pageSize: params?.pageSize,
            sort: params?.sort,
          },
          method: 'GET',
          validateStatus: (response, result) => {
            return response.status === 200 && !result.error
          },
        }
      },
      transformResponse: (responseData) => {
        const loadedNotes = responseData.result.map((note) => {
          note.id = note._id
          return note
        })
        responseData.result = loadedNotes
        return responseData
      },
      providesTags: (result, error, arg) => {
        // result is already transformed by transformResponse
        if (result?.result?.length > 0) {
          return [
            { type: 'Note', id: 'LIST' },
            ...result.result.map((note) => ({
              type: 'Note',
              id: note.id,
            })),
          ]
        } else return [{ type: 'Note', id: 'LIST' }]
      },
    }),
    getSharedNotes: builder.query({
      query: (params) => {
        return {
          url: `/v1/notes`,
          method: 'GET',
          params: {
            page: params?.page,
            pageSize: params?.pageSize,
            sort: params?.sort,
            isShared: true,
          },
          validateStatus: (response, result) => {
            return response.status === 200 && !result.error
          },
        }
      },
      transformResponse: (responseData) => {
        const loadedNotes = responseData.result.map((note) => {
          note.id = note._id
          return note
        })
        responseData.result = loadedNotes
        return responseData
      },
      providesTags: (result, error, arg) => {
        // result is already transformed by transformResponse
        if (result?.result?.length > 0) {
          return [
            { type: 'Note', id: 'LIST' },
            ...result.result.map((note) => ({
              type: 'Note',
              id: note.id,
            })),
          ]
        } else return [{ type: 'Note', id: 'LIST' }]
      },
    }),
    getSharedNotesByUserId: builder.query({
      query: (params) => {
        return {
          url: '/v1/notes',
          params: {
            userId: params.userId,
            isShared: true,
          },
          method: 'GET',
          validateStatus: (response, result) => {
            return response.status === 200 && !result.error
          },
        }
      },
      transformResponse: (responseData) => {
        const loadedNotes = responseData.result.map((note) => {
          note.id = note._id
          return note
        })
        responseData.result = loadedNotes
        return responseData
      },
      providesTags: (result, error, arg) => {
        // result is already transformed by transformResponse
        if (result?.result?.length > 0) {
          return [
            { type: 'Note', id: 'LIST' },
            ...result.result.map((note) => ({
              type: 'Note',
              id: note.id,
            })),
          ]
        } else return [{ type: 'Note', id: 'LIST' }]
      },
    }),
    updateNote: builder.mutation({
      query: (initialNoteData) => ({
        url: `/v1/notes/${initialNoteData.noteId}`,
        method: 'PATCH',
        body: {
          ...initialNoteData,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Note', id: arg.noteId },
      ],
    }),

    getNoteById: builder.query({
      query: ({ noteId }) => ({
        url: `/v1/notes/${noteId}`,
        validateStatus: (response, result) => {
          return response.status === 200 && !result.error
        },
      }),
      providesTags: (result, error, arg) => [{ type: 'Note', id: arg.noteId }],
    }),
    getNoteByShareId: builder.query({
      query: ({ shareId }) => ({
        url: '/v1/notes',
        params: {
          shareId: shareId,
        },
        validateStatus: (response, result) => {
          return response.status === 200 && !result.error
        },
      }),
    }),
    deleteNote: builder.mutation({
      query: (noteId) => ({
        url: `/v1/notes/${noteId}`,
        method: 'DELETE',
      }),
    }),
  }),
})

export const {
  useAddNewNoteMutation,
  useGetNotesQuery,
  useGetSharedNotesQuery,
  useGetSharedNotesByUserIdQuery,
  useLazyGetSharedNotesByUserIdQuery,
  useGetNoteByIdQuery,
  useLazyGetNoteByShareIdQuery,
  useDeleteNoteMutation,
  useUpdateNoteMutation,
} = notesApiSlice
