import React from 'react'
import { Box, useTheme } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'

const CustomDataGrid = ({
  isLoading,
  data,
  columns,
  rowCount,
  setSort,
  page,
  setPage,
  pageSize,
  setPageSize,
  paginationModel,
  setPaginationModel,
  height = '75vh',
}) => {
  const theme = useTheme()

  return (
    <Box
      height={height}
      sx={{
        '& .MuiDataGrid-root': {
          border: 'none',
        },
        '& .MuiDataGrid-cell': {
          borderBottom: 'none',
        },
        '& .MuiDataGrid-columnHeader': {
          backgroundColor: theme.palette.background.alt,
          color: theme.palette.secondary[100],
          borderBottom: 'none',
        },
        '& .MuiDataGrid-virtualScroller': {
          backgroundColor: theme.palette.primary.light,
        },
        '& .MuiDataGrid-footerContainer': {
          backgroundColor: theme.palette.background.alt,
          color: theme.palette.secondary[100],
          borderTop: 'none',
        },
        '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
          color: `${theme.palette.secondary[200]} !important`,
        },
      }}
    >
      <DataGrid
        initialState={{
          pagination: { paginationModel: { pageSize: pageSize, page: 0 } },
        }}
        rowCount={rowCount}
        page={page}
        pagination
        paginationMode="server"
        sortingMode="server"
        pageSize={pageSize}
        pageSizeOptions={[5, 20, 100]}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        paginationModel={paginationModel}
        onPaginationModelChange={(newModel) => setPaginationModel(newModel)}
        onSortModelChange={(newSortModel) => setSort(...newSortModel)}
        loading={isLoading}
        rows={data || []}
        getRowId={(row) => row._id}
        columns={columns}
      />
    </Box>
  )
}

export default CustomDataGrid
