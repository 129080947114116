// color design tokens export
export const tokensDark = {
  grey: {
    0: '#ffffff', // manually
    10: '#f6f6f6', // manually adjusted
    50: '#f0f0f0', // manually adjusted
    100: '#dfe1e4',
    200: '#c0c2ca',
    300: '#a0a4af',
    400: '#818595',
    500: '#61677a',
    600: '#4e5262',
    700: '#3a3e49',
    800: '#272931',
    900: '#131518',
    1000: '#000000', // manually adjusted
  },
  primary: {
    100: '#d9dcf0',
    200: '#b2b9e1',
    300: '#8c97d3',
    400: '#6574c4',
    500: '#3f51b5',
    600: '#324191',
    700: '#26316d',
    800: '#192048',
    900: '#0d1024',
  },
  secondary: {
    100: '#ffe5fa',
    200: '#ffcaf5',
    300: '#feb0ef',
    400: '#fe95ea',
    500: '#fe7be5',
    600: '#cb62b7',
    700: '#984a89',
    800: '#66315c',
    900: '#33192e',
  },
}

// function that reverses the color palette
function reverseTokens(tokensDark) {
  const reversedTokens = {}
  Object.entries(tokensDark).forEach(([key, val]) => {
    const keys = Object.keys(val)
    const values = Object.values(val)
    const length = keys.length
    const reversedObj = {}
    for (let i = 0; i < length; i++) {
      reversedObj[keys[i]] = values[length - i - 1]
    }
    reversedTokens[key] = reversedObj
  })
  return reversedTokens
}
export const tokensLight = reverseTokens(tokensDark)

// mui theme settings
export const themeSettings = (mode) => {
  return {
    palette: {
      mode: mode,
      ...(mode === 'dark'
        ? {
            // palette values for dark mode
            primary: {
              ...tokensDark.primary,
              main: tokensDark.primary[400],
              light: tokensDark.primary[400],
            },
            secondary: {
              ...tokensDark.secondary,
              main: tokensDark.secondary[300],
            },
            neutral: {
              ...tokensDark.grey,
              main: tokensDark.grey[500],
            },
            background: {
              default: tokensDark.primary[600],
              alt: tokensDark.primary[500],
            },
            mono: {
              flip: '#f6f6f6',
            },
          }
        : {
            // palette values for light mode
            primary: {
              ...tokensLight.primary,
              main: tokensDark.grey[50],
              light: tokensDark.grey[100],
            },
            secondary: {
              ...tokensLight.secondary,
              main: tokensDark.secondary[600],
              light: tokensDark.secondary[700],
            },
            neutral: {
              ...tokensLight.grey,
              main: tokensDark.grey[500],
            },
            background: {
              default: tokensDark.grey[0],
              alt: tokensDark.grey[50],
            },
            mono: {
              flip: '#0d1024',
            },
          }),
    },
    typography: {
      fontFamily: ['Inter', 'sans-serif'].join(','),
      fontSize: 12,
      h1: {
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontSize: 40,
      },
      h2: {
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontSize: 32,
      },
      h3: {
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontSize: 24,
      },
      h4: {
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontSize: 20,
      },
      h5: {
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontSize: 16,
      },
      h6: {
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontSize: 14,
      },
      logo: {
        fontFamily: ['Inria Serif', 'serif'].join(','),
        fontSize: 20,
      },
    },
  }
}
