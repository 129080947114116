import React from 'react'
import { Box } from '@mui/material'
import adminDog from 'assets/admin-dog.png'

const AdminHome = () => {
  return (
    <Box
      width="100%"
      height="100%"
      p="1.5rem"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <img src={adminDog} alt="Logo" width="200px" height="200px" />
    </Box>
  )
}

export default AdminHome
