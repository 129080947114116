import {
  Box,
  Button,
  Checkbox,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'

import { Formik } from 'formik'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import { useLoginMutation } from './authApiSlice'
import { setCredentials } from './authSlice'
import usePersist from '../../hooks/usePersist'

const loginSchema = yup.object().shape({
  username: yup.string().required('必須'),
  password: yup.string().required('必須'),
})

const initialValuesLogin = {
  username: '',
  password: '',
}

const Form = () => {
  const { palette } = useTheme()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [errMsg, setErrMsg] = useState('')
  // const [isOpenAiChecked, setIsOpenAiChecked] = useState(false)
  const [isAiAnswer, setIsAiAnswer] = useState(false)
  const [isAccurate, setIsAccurate] = useState(false)
  const [isPersonalInfo, setIsPersonalInfo] = useState(false)

  const [login] = useLoginMutation()
  const [persist, setPersist] = usePersist()

  const handleFormSubmit = async (values, onSubmitProps) => {
    if (!isAiAnswer) {
      setErrMsg('AIの利用に同意してください(チェックボックス)')
      return
    }
    if (!isAccurate) {
      setErrMsg(
        'AIの回答が100%正確ではないことを理解してください(チェックボックス)'
      )
      return
    }
    if (!isPersonalInfo) {
      setErrMsg('個人情報は質問に含めないでください(チェックボックス)')
      return
    }
    // if (!isOpenAiChecked) {
    //   setErrMsg('OpenAIの利用規約に同意してください(チェックボックス)')
    //   return
    // }
    try {
      const { token: accessToken, refreshToken } = await login(values).unwrap()
      dispatch(setCredentials({ accessToken, refreshToken }))

      navigate('/question')
    } catch (err) {
      if (!err.status) {
        setErrMsg('No Server Response')
      } else if (err.status === 400) {
        setErrMsg('Missing Username or Password')
      } else if (err.status === 401) {
        setErrMsg('Unauthorized')
      } else {
        setErrMsg(err.data?.error.message)
      }
    }
    onSubmitProps.resetForm()
  }

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValuesLogin}
      validationSchema={loginSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
          >
            <TextField
              label="ユーザー名"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.username}
              id="username"
              name="username"
              autoComplete="username"
              error={touched.username && Boolean(errors.username)}
              helperText={touched.username && errors.username}
              sx={{
                gridColumn: 'span 4',
                '& label.Mui-focused': {
                  color: '#A0AAB4',
                },
                '& .MuiInput-underline:after': {
                  borderBottomColor: '#B2BAC2',
                },
                '& .MuiOutlinedInput-input': {
                  color: '#6F7E8C',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#E0E3E7',
                  },
                  '&:hover fieldset': {
                    borderColor: '#B2BAC2',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#6F7E8C',
                  },
                },
              }}
            />
            <TextField
              label="パスワード"
              type="password"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.password}
              id="password"
              name="password"
              error={Boolean(touched.password) && Boolean(errors.password)}
              helperText={touched.password && errors.password}
              sx={{
                gridColumn: 'span 4',
                '& .MuiOutlinedInput-input': {
                  color: '#6F7E8C',
                },
                '& label.Mui-focused': {
                  color: '#A0AAB4',
                },
                '& .MuiInput-underline:after': {
                  borderBottomColor: '#B2BAC2',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#E0E3E7',
                  },
                  '&:hover fieldset': {
                    borderColor: '#B2BAC2',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#6F7E8C',
                  },
                },
              }}
            />
            <Box sx={{ width: '100%', gridColumn: 'span 4' }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  gridColumn: 'span 4',
                  alignItems: 'center',
                }}
              >
                <Typography sx={{ color: 'black' }}>
                  質問の回答にはAIを利用しています
                </Typography>

                <Checkbox
                  checked={isAiAnswer}
                  onChange={() => setIsAiAnswer(!isAiAnswer)}
                  name="isAiAnswer"
                  value={isAiAnswer}
                  sx={{
                    color: 'black',
                    '&.Mui-checked': {
                      color: 'black',
                    },
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  gridColumn: 'span 4',
                  alignItems: 'center',
                }}
              >
                <Typography sx={{ color: 'black' }}>
                  AIの回答が100%正確ではないことを理解しています
                </Typography>

                <Checkbox
                  checked={isAccurate}
                  onChange={() => setIsAccurate(!isAccurate)}
                  name="isAccurate"
                  value={isAccurate}
                  sx={{
                    color: 'black',
                    '&.Mui-checked': {
                      color: 'black',
                    },
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  gridColumn: 'span 4',
                  alignItems: 'center',
                }}
              >
                <Typography sx={{ color: 'black' }}>
                  個人情報は質問に含めないでください
                </Typography>

                <Checkbox
                  checked={isPersonalInfo}
                  onChange={() => setIsPersonalInfo(!isPersonalInfo)}
                  name="isPersonalInfo"
                  value={isPersonalInfo}
                  sx={{
                    color: 'black',
                    '&.Mui-checked': {
                      color: 'black',
                    },
                  }}
                />
              </Box>

              {/* <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  gridColumn: 'span 4',
                  alignItems: 'center',
                }}
              >
                <Typography sx={{ color: 'black' }}>
                  OpenAIの
                  <Link
                    // to="https://openai.com/policies/terms-of-use"
                    // onClick={handleLinkClick}
                    href={'https://openai.com/policies/terms-of-use'}
                    target="_blank"
                    sx={{
                      color: 'black',
                      textDecoration: 'underline',
                      fontWeight: 'bold',
                    }}
                  >
                    利用規約
                  </Link>
                  に同意します
                </Typography>

                <Checkbox
                  checked={isOpenAiChecked}
                  onChange={() => setIsOpenAiChecked(!isOpenAiChecked)}
                  name="openAiCheckBox"
                  value={isOpenAiChecked}
                  sx={{
                    color: 'black',
                    '&.Mui-checked': {
                      color: 'black',
                    },
                  }}
                />
              </Box> */}
            </Box>
          </Box>

          {/* SUBMIT BUTTON */}
          <Box>
            <Button
              fullWidth
              type="submit"
              sx={{
                m: '2rem 0',
                p: '1rem',
                backgroundColor: palette.grey[700],
                color: palette.grey[100],
                '&:hover': { backgroundColor: '#1C225A' },
              }}
            >
              LOGIN
            </Button>
          </Box>

          {/* Error Msg */}
          {errMsg && (
            <Box
              sx={{
                color: palette.error.main,
                fontWeight: 'bold',
                textAlign: 'center',
              }}
            >
              {errMsg}
            </Box>
          )}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'end',
              gridColumn: 'span 4',
              alignItems: 'center',
            }}
          >
            <Typography sx={{ color: '#6F7E8C' }}>
              このデバイスを信用しますか
            </Typography>
            <Checkbox
              checked={persist}
              onChange={() => setPersist(!persist)}
              name="persist"
              value={persist}
              sx={{
                color: '#6F7E8C',
                '&.Mui-checked': {
                  color: 'black',
                },
              }}
            />
          </Box>
        </form>
      )}
    </Formik>
  )
}

export default Form
