import React, { useEffect, useState } from 'react'
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  MenuItem,
  Select,
  Snackbar,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import RemoveCircle from '@mui/icons-material/RemoveCircle'

import useAuth from 'hooks/useAuth'

import FlexBetween from 'components/FlexBetween'
import BlinkingText from 'components/BlinkingText'
import MarkDownLayout from 'components/MarkDownLayout'

import { useAddNewAiQuestionMutation } from 'features/ai/aiApiSlice'

import { aiReqType } from 'constants/aiReqType'
import { TEXTAREA_STORAGE_KEYS } from 'constants/textAreaLocalstorageKey'
import CopyToClipboard from 'react-copy-to-clipboard'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

const formatSelection = ['json', 'csv']

const DummyData = () => {
  const [elements, setElements] = useState([])
  const [aiOutputText, setAiOutputText] = useState('')
  const [noteId, setNoteId] = useState('')
  const { userId } = useAuth()
  const [errorMessage, setErrorMessage] = useState('')
  const [open, setOpen] = useState(false)
  const [title, setTitle] = useState('')
  const [number, setNumber] = useState(5)
  const [format, setFormat] = useState('json')

  const [isPreview, setIsPreview] = useState(true)

  const handleChange = () => {
    setIsPreview(!isPreview)
  }

  const [addNewAiQuestion, { isLoading }] = useAddNewAiQuestionMutation()

  useEffect(() => {
    addElement()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const showAlert = () => {
    setOpen(true)

    // 5秒後にアラートを閉じる
    setTimeout(() => {
      setOpen(false)
      setErrorMessage('')
    }, 5000)
  }

  useEffect(() => {
    if (!errorMessage) {
      return
    }
    showAlert()
  }, [errorMessage])

  useEffect(() => {
    const storedValue = localStorage.getItem(
      TEXTAREA_STORAGE_KEYS.TEXTAREA_DUMMY_DATA_RESPONSE
    )
    if (storedValue !== null) {
      setAiOutputText(storedValue)
    }
  }, [])
  useEffect(() => {
    if (aiOutputText !== '') {
      localStorage.setItem(
        TEXTAREA_STORAGE_KEYS.TEXTAREA_DUMMY_DATA_RESPONSE,
        aiOutputText
      )
    }
  }, [aiOutputText])

  const addElement = () => {
    const newElements = [...elements]
    newElements.push({
      variableName: '',
      content: '',
      contentType: '文字列',
    })
    setElements(newElements)
  }

  const removeElement = (index) => {
    const newElements = [...elements]
    newElements.splice(index, 1)
    setElements(newElements)
  }

  const handleVariableNameChange = (e, index) => {
    const newElements = [...elements]
    elements[index].variableName = e.target.value
    setElements(newElements)
  }

  const handleContentChange = (e, index) => {
    const newElements = [...elements]
    elements[index].content = e.target.value
    setElements(newElements)
  }

  const handleContentTypeChange = (e, index) => {
    const newElements = [...elements]
    elements[index].contentType = e.target.value
    setElements(newElements)
  }

  const handleTitleChange = (event) => {
    const title = event.target.value
    setTitle(title)
  }
  const handleNumberChange = (event) => {
    const number = event.target.value
    setNumber(number)
  }
  const handleFormatChange = (event) => {
    const format = event.target.value
    setFormat(format)
  }

  const handleFormSubmit = async () => {
    if (!title) {
      setErrorMessage('データの名前を入力してください')
      return
    }
    try {
      const data = await addNewAiQuestion({
        question: elements,
        title: title,
        number: number,
        format: format,
        aiReqType: aiReqType.DUMMY_DATA,
        userId: userId,
      }).unwrap()

      if (!data) return
      setAiOutputText(data.content)
      setNoteId(data.noteId)
      // setTextareaValue('')
    } catch (error) {
      setErrorMessage(error?.data?.error.message)
    }
  }

  return (
    <Box width="100%" sx={{ p: '1.5rem' }}>
      <TextField
        label="データの名前"
        value={title}
        onChange={(e) => {
          handleTitleChange(e)
        }}
      />
      <Divider sx={{ marginY: '1rem' }} />
      <Typography variant="h5" mb="10px">
        要素
      </Typography>
      {/* Input Data */}
      {[...Array(elements.length)].map((_, index) => (
        <FlexBetween key={index} sx={{ mb: '1rem' }}>
          <FlexBetween gap={3}>
            <TextField
              value={elements[index].variableName ?? ''}
              onChange={(e) => handleVariableNameChange(e, index)}
              label="変数名"
            />
            <TextField
              value={elements[index].content ?? ''}
              label="値"
              onChange={(e) => handleContentChange(e, index)}
            />
            <TextField
              value={elements[index].contentType ?? ''}
              label="データ種類"
              onChange={(e) => handleContentTypeChange(e, index)}
            />
          </FlexBetween>
          <IconButton
            onClick={() => {
              removeElement(index)
            }}
          >
            <RemoveCircle fontSize="large" />
          </IconButton>
        </FlexBetween>
      ))}
      <Button variant="contained" onClick={addElement} sx={{ mt: '1rem' }}>
        追加
      </Button>
      <Divider sx={{ marginY: '1rem' }} />
      <FlexBetween width={'50%'} gap="5px">
        <TextField
          label="データ数"
          type="number"
          value={number}
          onChange={(e) => {
            handleNumberChange(e)
          }}
        />
        <Select
          value={format}
          name="roles"
          onChange={(e) => {
            handleFormatChange(e)
          }}
          sx={{
            // gridColumn: 'span 2',
            minWidth: '100px',
          }}
        >
          {formatSelection.map((format) => (
            <MenuItem key={format} value={format}>
              {format}
            </MenuItem>
          ))}
        </Select>
      </FlexBetween>
      <Divider sx={{ marginY: '1rem' }} />
      {/* Generate Data */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: !!noteId ? 'space-between' : 'end',
          mb: '10px',
        }}
      >
        {!!noteId && (
          <Typography variant="body2">{'ノートID: ' + noteId}</Typography>
        )}
        <Button
          variant="contained"
          onClick={handleFormSubmit}
          disabled={isLoading}
        >
          {isLoading ? <CircularProgress size={24} color="inherit" /> : '送信'}
        </Button>
      </Box>
      <Divider />

      {isLoading && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center', // 垂直方向の中央寄せを追加
            p: '1.5rem 0rem',
          }}
        >
          <BlinkingText text={'作成中...'} interval={800} />
        </Box>
      )}
      {/* AI OUTPUT */}
      {aiOutputText !== '' && (
        <Box sx={{}}>
          <FlexBetween>
            <Typography variant="h5" mb="10px">
              AIの回答
            </Typography>

            <FlexBetween>
              {isPreview ? (
                <Typography variant="body2">プレビュー</Typography>
              ) : (
                <FlexBetween gap="0.5rem">
                  <CopyToClipboard text={aiOutputText}>
                    <IconButton color="white" edge="end">
                      <ContentCopyIcon />
                    </IconButton>
                  </CopyToClipboard>
                  <Typography variant="body2">マークダウン</Typography>
                </FlexBetween>
              )}
              <Switch
                checked={isPreview}
                onChange={handleChange}
                color="primary"
                name="exampleSwitch"
                inputProps={{ 'aria-label': 'example switch' }}
              />
            </FlexBetween>
          </FlexBetween>

          {isPreview ? (
            <MarkDownLayout markdown={aiOutputText} />
          ) : (
            <Typography variant="body1" mb="10px">
              {aiOutputText}
            </Typography>
          )}
        </Box>
      )}
      <Snackbar
        open={open}
        autoHideDuration={6000} // アラートが自動的に閉じるまでの時間（ミリ秒）
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity="error" onClose={() => setOpen(false)}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default DummyData
