export const TEXTAREA_STORAGE_KEYS = {
  // Question
  TEXTAREA_QUESTION: 'TEXTAREA_QUESTION',
  TEXTAREA_QUESTION_RESPONSE: 'TEXTAREA_QUESTION_RESPONSE',
  // Debug
  TEXTAREA_DEBUG: 'TEXTAREA_DEBUG',
  TEXTAREA_DEBUG_ERROR: 'TEXTAREA_DEBUG_ERROR',
  TEXTAREA_DEBUG_RESPONSE: 'TEXTAREA_DEBUG_RESPONSE',
  // Refactoring
  TEXTAREA_REFACTORING: 'TEXTAREA_REFACTORING',
  TEXTAREA_REFACTORING_RESPONSE: 'TEXTAREA_REFACTORING_RESPONSE',
  // Generate Code
  TEXTAREA_GENERATE_CODE: 'TEXTAREA_GENERATE_CODE',
  TEXTAREA_GENERATE_CODE_RESPONSE: 'TEXTAREA_GENERATE_CODE_RESPONSE',
  // Dummy Data
  TEXTAREA_DUMMY_DATA: 'TEXTAREA_DUMMY_DATA',
  TEXTAREA_DUMMY_DATA_RESPONSE: 'TEXTAREA_DUMMY_DATA_RESPONSE',
  // Analysis
  TEXTAREA_CODE_ANALYSIS: 'TEXTAREA_CODE_ANALYSIS',
  TEXTAREA_CODE_ANALYSIS_RESPONSE: 'TEXTAREA_CODE_ANALYSIS_RESPONSE',
  // Conversion
  TEXTAREA_CONVERSION: 'TEXTAREA_CONVERSION',
  TEXTAREA_CONVERSION_RESPONSE: 'TEXTAREA_CONVERSION_RESPONSE',
  // Generate Resource
  TEXTAREA_GENERATE_RESOURCE: 'TEXTAREA_GENERATE_RESOURCE',
  TEXTAREA_GENERATE_RESOURCE_KEYWORDS: 'TEXTAREA_GENERATE_RESOURCE_KEYWORDS',
  TEXTAREA_GENERATE_RESOURCE_RESPONSE: 'TEXTAREA_GENERATE_RESOURCE_RESPONSE',
  // Translation
  TEXTAREA_TRANSLATION: 'TEXTAREA_TRANSLATION',
  // Note
  TEXTAREA_NOTE: 'TEXTAREA_NOTE',
}

export const refreshAllTextArea = async () => {
  Object.values(TEXTAREA_STORAGE_KEYS).forEach((key) => {
    localStorage.removeItem(key)
  })
}
