import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Box, Button, Chip, Stack } from '@mui/material'
import CustomDataGrid from 'components/CustomDataGrid'

import { useGetNotesQuery } from 'features/notes/notesApiSlice'
import useAuth from 'hooks/useAuth'

import convertToJapanTime from 'utils/convertToJapanTime'
import FlexBetween from 'components/FlexBetween'
import { aiReqTypeToCaption } from 'constants/aiReqType'

const Notes = () => {
  const { userId } = useAuth()

  const [notes, setNotes] = useState([])
  const [sort, setSort] = useState({})
  const [rowCount, setRowCount] = useState(0)
  const navigate = useNavigate()
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 20,
    page: 0,
  })

  // const isSmallWindow = useMediaQuery('(max-width:800px)')

  const {
    data: noteData,
    isSuccess: isNoteSuccess,
    isLoading: noteLoading,
  } = useGetNotesQuery(
    // 'notesList',
    {
      userId: userId,
      page: paginationModel.page,
      pageSize: paginationModel.pageSize,
      sort: JSON.stringify(sort),
    },
    {
      // useQueryの設定を追加
      refetchOnMountOrArgChange: true, // userIdもしくはisSharedが変更されたら再取得
      skip: !userId, // userIdがない場合はskip
    }
  )

  useEffect(() => {
    if (isNoteSuccess) {
      const { result } = noteData

      if (result) {
        setNotes(noteData.result)
        setRowCount(noteData.count || 0)
        // console.log(noteData.result)
      }
    }
  }, [noteData]) // eslint-disable-line react-hooks/exhaustive-deps

  const columns = [
    // { field: '_id', headerName: 'ID', flex: 0.5 },
    {
      field: 'createdAt',
      headerName: '作成日',
      valueGetter: (params) => {
        return convertToJapanTime(params.row.createdAt) // 日本時間に変換
      },
    },
    {
      field: 'isShared',
      headerName: '共有',
      valueGetter: (params) => {
        return params.row.isShared ? '共有中' : '未'
      },
    },
    {
      field: 'tags',
      headerName: 'タグ',
      // width: 200,
      // flex: 0.5,
      //   type: 'singleSelect',
      //   valueOptions: [...new Set(userData.map((o) => o.role).flat())],
      renderCell: (params) => (
        <FlexBetween gap="2rem" width="300px">
          {params.row.tags && (
            <Stack direction="row" spacing={0.25}>
              {params.row.tags.map((tag) => (
                <Chip label={tag} key={tag} />
              ))}
            </Stack>
          )}
        </FlexBetween>
      ),
    },
    {
      field: 'contentCategory',
      headerName: 'カテゴリー',
      valueGetter: (params) => {
        const category = aiReqTypeToCaption[params.row.contentCategory] || ''
        return category
      },
    },
    { field: 'contentTitle', headerName: 'タイトル', flex: 1 },
    {
      field: 'detail',
      headerName: '詳細',
      renderCell: (params) => {
        return (
          <Stack spacing={0.25}>
            <Button
              onClick={() => {
                navigate(`/note_detail/${params.row._id}`)
              }}
              variant="contained"
            >
              詳細
            </Button>
          </Stack>
        )
      },
    },
  ]

  return (
    <Box p="1.5rem">
      {noteLoading ? (
        <Box m="20px">Note Loading...</Box>
      ) : (
        <Box>
          <CustomDataGrid
            rowCount={rowCount}
            isLoading={noteLoading}
            data={notes}
            columns={columns}
            sort={sort}
            setSort={setSort}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
          />
        </Box>
      )}
    </Box>
  )
}

export default Notes
