import React, { useEffect, useState } from 'react'
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Snackbar,
  Switch,
  Typography,
} from '@mui/material'
// import AutoCloseAlert from 'components/AutoCloseAlert'
import { TextareaAutosize } from '@mui/base/TextareaAutosize'

import MarkDownLayout from 'components/MarkDownLayout'
import BlinkingText from 'components/BlinkingText'

import { aiReqType } from 'constants/aiReqType'

import { useAddNewAiQuestionMutation } from 'features/ai/aiApiSlice'
import useAuth from 'hooks/useAuth'
import { TEXTAREA_STORAGE_KEYS } from 'constants/textAreaLocalstorageKey'
import FlexBetween from 'components/FlexBetween'
import CopyToClipboard from 'react-copy-to-clipboard'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

const Question = () => {
  const [textareaValue, setTextareaValue] = useState('')
  const [aiOutputText, setAiOutputText] = useState('')
  const [noteId, setNoteId] = useState('')
  const { userId } = useAuth()
  const [errorMessage, setErrorMessage] = useState('')
  const [open, setOpen] = useState(false)

  const [isPreview, setIsPreview] = useState(true)

  const handleChange = () => {
    setIsPreview(!isPreview)
  }

  const [addNewAiQuestion, { isLoading }] = useAddNewAiQuestionMutation()

  const showAlert = () => {
    setOpen(true)

    // 5秒後にアラートを閉じる
    setTimeout(() => {
      setOpen(false)
      setErrorMessage('')
    }, 5000)
  }
  // save textarea content to local storage whenever it changes
  useEffect(() => {
    const storedValue = localStorage.getItem(
      TEXTAREA_STORAGE_KEYS.TEXTAREA_QUESTION
    )
    if (storedValue !== null) {
      setTextareaValue(storedValue)
    }
  }, [])
  useEffect(() => {
    if (textareaValue !== '') {
      localStorage.setItem(
        TEXTAREA_STORAGE_KEYS.TEXTAREA_QUESTION,
        textareaValue
      )
    }
  }, [textareaValue])

  useEffect(() => {
    const storedValue = localStorage.getItem(
      TEXTAREA_STORAGE_KEYS.TEXTAREA_QUESTION_RESPONSE
    )

    if (storedValue !== null) {
      setAiOutputText(storedValue)
    }
  }, [])
  useEffect(() => {
    if (aiOutputText !== '') {
      localStorage.setItem(
        TEXTAREA_STORAGE_KEYS.TEXTAREA_QUESTION_RESPONSE,
        aiOutputText
      )
    }
  }, [aiOutputText])

  useEffect(() => {
    if (!errorMessage) {
      return
    }
    showAlert()
  }, [errorMessage])

  useEffect(() => {
    if (!errorMessage) {
      return
    }
    showAlert()
  }, [errorMessage])

  const handleFormSubmit = async () => {
    try {
      const data = await addNewAiQuestion({
        question: textareaValue,
        aiReqType: aiReqType.STANDARD,
        userId: userId,
      }).unwrap()

      if (!data) return
      setAiOutputText(data.content)
      setNoteId(data.noteId)
      // setTextareaValue('')
    } catch (error) {
      setErrorMessage(error?.data?.error.message)
    }
  }

  const handleTextareaChange = (event) => {
    setTextareaValue(event.target.value)
  }

  return (
    <Box width="100%" p="1.5rem">
      {/* テキストインプットエリア */}
      <TextareaAutosize
        id="standard-question"
        name="standard-question"
        aria-label="textarea"
        placeholder="質問内容を入力してください"
        rowsmin={3}
        autoComplete="standard-question"
        value={textareaValue}
        onChange={handleTextareaChange}
        style={{
          width: '100%',
          height: '60vh',
          padding: '10px',
          fontSize: '1rem',
          marginBottom: '10px',
          overflow: 'auto',
          resize: 'none',
        }}
      />
      {/* ボタン */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: !!noteId ? 'space-between' : 'end',
          mb: '10px',
        }}
      >
        {!!noteId && (
          <Typography variant="body2">{'ノートID: ' + noteId}</Typography>
        )}
        <Button
          id="submit-button"
          name="submit-button"
          variant="contained"
          onClick={handleFormSubmit}
          disabled={isLoading}
        >
          {isLoading ? <CircularProgress size={24} color="inherit" /> : '送信'}
        </Button>
      </Box>
      <Divider />

      {isLoading && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center', // 垂直方向の中央寄せを追加
            p: '1.5rem 0rem',
          }}
        >
          <BlinkingText text={'作成中...'} interval={800} />
        </Box>
      )}

      {/* AI OUTPUT */}
      {aiOutputText !== '' && (
        <Box sx={{}}>
          <FlexBetween>
            <Typography variant="h5" mb="10px">
              AIの回答
            </Typography>

            <FlexBetween>
              {isPreview ? (
                <Typography variant="body2">プレビュー</Typography>
              ) : (
                <FlexBetween gap="0.5rem">
                  <CopyToClipboard text={aiOutputText}>
                    <IconButton color="white" edge="end">
                      <ContentCopyIcon />
                    </IconButton>
                  </CopyToClipboard>
                  <Typography variant="body2">マークダウン</Typography>
                </FlexBetween>
              )}
              <Switch
                checked={isPreview}
                onChange={handleChange}
                color="primary"
                name="exampleSwitch"
                inputProps={{ 'aria-label': 'example switch' }}
              />
            </FlexBetween>
          </FlexBetween>

          {isPreview ? (
            <MarkDownLayout markdown={aiOutputText} />
          ) : (
            <Typography variant="body1" mb="10px">
              {aiOutputText}
            </Typography>
          )}
        </Box>
      )}
      <Snackbar
        open={open}
        autoHideDuration={6000} // アラートが自動的に閉じるまでの時間（ミリ秒）
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity="error" onClose={() => setOpen(false)}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default Question
